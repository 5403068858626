import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { setGridFilter } from "../../utils/gridUtil";
/**
 * CommonAreaMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const CommonAreaMiddleware = (store) => (next) => async (action) => {
    // Our middleware
    const result    = next(action);
    let endpoint;
    switch (action.type) {
        case "FETCH_COMMONAREA_DROPDOWN":
        let queryParams = {};
        endpoint = `${microServices.commonarea}/v1/commonareas`;
        if (action?.payload?.hasOwnProperty('params')) {
            endpoint = setGridFilter(endpoint, action.payload.params);
          } 
            queryParams['sort'] = 'common_area_name';
            queryParams['per_page'] = 0;
            
        APIservice
          .get(endpoint, queryParams)
          .then((response)=>{
            let commonAreasList = prepareCommonAreaDropdown(response);
            store.dispatch({ 
              type: 'SET_COMMONAREA_DROPDOWN_LIST', 
              payload: commonAreasList,
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_COMMONAREA_DROPDOWN_LIST', 
                payload: [],
              });
            } 
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })

        break;

        case "CREATE_COMMON_AREA":   
            endpoint = microServices.commonarea + action.endpoint;
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                      variant: 'success',
                      message: 'Common Area Created Successfully!',
                      componentName: action.componentName,
                      response: response.data,
                  }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to Create Common Area'
                    }
                });
                } else {
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            }).finally(()=>{
                store.dispatch(isLoader(false));
              })
        break;
        case "ACTIVE_INACTIVE_COMMONAREA":
          endpoint = `${microServices.commonarea}/v1/commonareas`;
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Common area has been ${action.payload.message}d successfully.`,
                  componentName:'ActiveInactiveCommonArea'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.message} common Area information!`
                  }
                });
              } else {
                  let err = {componentName:'ActiveInactiveCommonArea',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "ACTIVE_INACTIVE_COMMON_AREA_TEMPLATE":
          endpoint = microServices.commonarea+'/v1/template';
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Common area has been ${action.payload.message}d successfully.`,
                  componentName:'ActiveInactiveCommonArea'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.message} Common Area Template!`
                  }
                });
              } else {
                  let err = {componentName:'ActiveInactiveTemplate',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
       
        case "UPLOAD_CA_TEMPLATE": {
          endpoint = `${microServices.commonarea}/v1/templates/upload`;
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  componentName: action.payload.componentName,
                  message: action.payload.message,
                }
              });
            })
            .catch((error) => {
              let err = {
                componentName: action.payload.componentName,
                statusCode: error.response.status,
                data: error.response.data
              };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          ;
          break;
        }

        case "PRINT_COMMON_AREA_REPORT":
            let caPrintApi = action.payload.printApi;
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataWithParams(caPrintApi,"")
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = blobUrl;
                   link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                   document.body.appendChild(link);
                   link.click(); 
                   link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Common Area Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadCommonAreaReport',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
            break;

            case "EXPORT_COMMON_AREA_REPORT":
                let exportApi = action.payload.exportApi;
                store.dispatch(isLoader(true));
                APIservice.
                getBlobDataWithParams(exportApi,"")
                .then((response)=>{
                    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "List of Common Areas"}.xlsx`);
                    document.body.appendChild(link);
                    link.click(); 
                    link.parentNode.removeChild(link);
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to Export Common Area Report!',
                            }
                        });
                    } else {
                        let err = {componentName:'ExportCommonAreaReport',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                });
                break;

        case "EXPORT_COMMON_AREA_TEMPLATE":
          let api = action.payload.exportApi;
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataWithParams(api,"")
          .then((response)=>{
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : `List of Common Area Templates`}.xlsx`);
            document.body.appendChild(link);
            link.click(); 
            link.parentNode.removeChild(link);
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                      variant:'danger',
                      message: 'Unable to Download Report!',
                  }
              });
            } else {
              let err = {componentName:'DownloadTemplate',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
      break;

      case "PRINT_COMMON_AREA_TEMPLATE":
        let printApi = action.payload.printApi;
        store.dispatch(isLoader(true));
        APIservice.
        getBlobDataWithParams(printApi,"")
        .then((response)=>{
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : `template`}.pdf`);
          document.body.appendChild(link);
          link.click(); 
          link.parentNode.removeChild(link);
        })
        .catch((error)=>{
          if (error.response.status !== 422) {
            store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'danger',
                    message: 'Unable to Download Report!',
                }
            });
          } else {
            let err = {componentName:'TemplatePdf',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          }
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        });
        break;
        
        case "FETCH_COMMON_AREA":
          endpoint = `${microServices.commonarea}/v1/commonareas/${action.payload.id}`;
          store.dispatch(isLoader(true));
          APIservice
          .get(endpoint)
          .then((response)=>{
            if(response && response.data){              
              store.dispatch({ 
                type: 'SET_DATA_FOR_EDIT', 
                payload: {
                  data:response.data.data
                }
              });
            }
          })
          .catch((error)=>{
            let err = {componentName:'ViewCommonArea', statusCode: error.response.status, data: error.response.data};
            store.dispatch(setError(err));
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "UPDATE_COMMON_AREA":
          endpoint  = `${microServices.commonarea}/${action.endpoint}`;
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint + '/' + action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: { 
                  variant:'success',
                  message: action.message,
                  componentName:action.componentName
                }
              });
            })
            .catch((error)=>{            
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: 'SHOW_ALERT', 
                        payload: {
                        variant:'danger',
                        message: 'Unable to Update Common Area',
                        componentName: action.componentName
                        }
                    });
                } else {
                    let err = {componentName: action.componentName, statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));
                }
            }).finally(()=>{
              store.dispatch(isLoader(false));
            })
      break;

        case "GET_COMMONAREA_TEMPLATE":
          endpoint = microServices.commonarea+'/v1/templates/'+action.payload;
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_COMMONAREA_TEMPLATE', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'viewCommonAreaTemplate',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;


        case "FETCH_CA_TEMPLATE_AREA_BY_TEMPLATE":
          endpoint = microServices.commonarea+'/v1/templates/'+action.payload+'/area';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_CA_TEMPLATE_AREA_BY_TEMPLATE', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'CommonAreaTemplateArea',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;


        case "FETCH_CA_TEMPLATE_ITEM_BY_AREA":
          endpoint = microServices.commonarea+'/v1/templates/'+action.payload;
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_CA_TEMPLATE_ITEM_BY_AREA', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'CommonAreaTemplate',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;


        case "CREATE_CA_TEMPLATE_AREA":   
            endpoint = microServices.commonarea + action.payload.endpoint;
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                      variant: 'success',
                      message:  action.payload?.message,
                      componentName: action.payload?.componentName
                  }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to Create '
                    }
                });
                } else {
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            }).finally(()=>{
                store.dispatch(isLoader(false));
              })
        break;


        case "DELETE_COMMON_AREA_FIELD" : 
          endpoint = microServices.commonarea+ action.payload.endpoint;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: action.payload.message,
                componentName:action.payload.componentName,
                ca_templates_area_id : action.payload.ca_templates_area_id
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: error.response.data?.message
                }
              });
            } else {
              let err = {componentName:action.payload.componentName, statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;


        case "FETCH_CA_TEMPLATE_AREA_ITEMS":
          endpoint = microServices.commonarea+'/v1/templates/area/'+action.payload.ca_templates_area_id+'/items';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_CA_TEMPLATE_AREA_ITEMS', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'CommonAreaTemplateItem',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;


        case "ADD_CA_TEMPLATE_ITEM":
            endpoint = microServices.commonarea+'/v1/templates/items';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint,
            action.payload)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Common Area Item added successfully!',
                        componentName:'AddCATemplateItem',
                        ca_templates_area_id : action.payload.ca_templates_area_id
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'ErrAddCATemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case 'UPDATE_CA_TEMPLATE_ITEM':
            endpoint = microServices.commonarea+'/v1/templates/items';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.ca_templates_items_id, 
            action.payload)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Common Area Item updated successfully!',
                        componentName:'EditTemplateItem',
                        ca_templates_area_id : action.payload.ca_templates_area_id
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'ErrEditCaTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;


        case "UPDATE_CA_TEMPLATE_ITEM_SORT_ORDER":
            endpoint = microServices.commonarea+'/v1/templates';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.ca_templates_area_id+'/items/display-order', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item Display Order updated successfully!',
                        componentName:'UpdateCaTemplateItemDisplayOrder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item display order information!',
                        }
                    });
                } else {
                    let err = {componentName:'ErrUpdateCaTemplateItemDisplayOrder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;


      case "UPDATE_COMMON_AREA_TEMPLATE":
        store.dispatch(isLoader(true));
        endpoint = microServices.commonarea+action.payload.url;
        APIservice
          .put(endpoint , action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant:'success',
                message: action.payload.message,
                componentName: action.payload.componentName,
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: 'Unable to save !',
                }
              });
            } else {
              let err = { componentName: action.payload.componentName, statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;

      case "FETCH_COMMONAREA_TEMPLATES_DROPDOWN": {
        endpoint = `${microServices.commonarea}/v1/templates`;
        let queryParams = {};
        store.dispatch(isLoader(true));
        if (action?.payload?.hasOwnProperty('params')) {
          endpoint = setGridFilter(endpoint, action.payload.params);
        } 
        queryParams['per_page'] = 0;
        APIservice.get(endpoint, queryParams)
          .then((response) => {
            let list = prepareCommonAreaTemplatesDropdown(response);
            store.dispatch({ 
              type: 'SET_COMMONAREA_TEMPLATES_DROPDOWN_LIST', 
              payload: list,
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_COMMONAREA_TEMPLATES_DROPDOWN_LIST', 
                payload: [],
              });
            } 
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          });
          break;
        }

      case "FETCH_COMMON_AREA_STAFF":
        let filterUrl = '';
        if (action.payload) {
          filterUrl = prepareFilterUrl(action.payload.filter);
        }
        endpoint = microServices.base + '/v1/company/staff/' + action.payload.userType + '?' + filterUrl+'per_page=0';
        APIservice
        .get(endpoint)
        .then((response) => {
          let staffList = [];
          staffList = prepareCommonAreaStaff(response);
          store.dispatch({
            type: 'SET_CL_CA_DROPDOWN',
            payload: staffList,
          });
        })

        .catch((error) => {
          if (error.response?.status !== 422) {
            store.dispatch({
              type: 'SET_CL_CA_DROPDOWN',
              payload: [],
            });
          }
        })
        break;

        case "SCHEDULE_COMMON_AREA_APPTS": {
          endpoint = `${microServices.commonarea}/v1/appointments`;
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  componentName: action.payload.componentName,
                  message: action.payload.message,
                }
              });
            })
            .catch((error) => {
              let err = {
                componentName: action.payload.componentName,
                statusCode: error.response.status,
                data: error.response.data
              };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          ;
          break;
        }
        
        case "GET_COMMONAREA_APPOINTMENT":
          endpoint = microServices.commonarea+action.payload.url;
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_COMMONAREA_APPOINTMENT',
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:action.payload.componentName,statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

      case "UPDATE_COMMON_APPOINTMENT_STATUS":
      endpoint = microServices.commonarea + "/v1/appointments/cancel/" + action.payload;
      store.dispatch(isLoader(true));
      APIservice.put(endpoint)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: 'Appointment Cancelled Successfully',
              componentName: "UpdateCommonAreaAppointmentsStatus"
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to update Appointment status!",
              },
            });
          } else {
            let err = {
              componentName: "UpdateCommonAreaAppointmentsStatus",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;

        case "FETCH_COMMON_AREA_APPOINTMENTS": {
          endpoint = action.payload.url;
          store.dispatch(isLoader(true));
          APIservice.get(endpoint)
            .then((response)=>{
              if(response?.data){
                store.dispatch({ 
                type: 'SET_COMMON_AREA_APPOINTMENTS',
                payload: {
                    data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName: action.payload.componentName, statusCode: error.response.status, data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
        }

        case "MASS_EDIT_COMMON_AREA_APPOINTMENTS": {
          endpoint = `${microServices.commonarea}/v1/appointments/mass-edit`;
          store.dispatch(isLoader(true));
          APIservice.put(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName: action.payload.componentName,
                message: action.payload.message,
              }
            });
          })
          .catch((error) => {
            let err = {
              componentName: action.payload.componentName,
              statusCode: error.response.status,
              data: error.response.data
            };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
          break;
        }

        case "CREATE_COMMONAREA_NOTE":
          endpoint = microServices.commonarea+'/v1/appointment/note';
          store.dispatch(isLoader(true));
          APIservice
          .post(endpoint, action.payload)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Note added Successfully.',
                componentName:'AddCommonAreaNote'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save notes information!'
                }
              });
            } else {
              let err = {componentName:'AddCommonAreaNote',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "DELETE_COMMONAREA_NOTE":
          endpoint = microServices.commonarea+'/v1/appointment/note/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Note deleted successfully.',
                componentName:'DeleteCommonAreaNote'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Note!'
                }
              });
            } else {
              let err = {componentName:'DeleteCommonAreaNote',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "DELETE_COMMONAREA_PHOTO":
          endpoint = microServices.commonarea+'/v1/appointment/photo/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeleteCommonAreaPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Item Photo!'
                }
              });
            } else {
              let err = {componentName:'DeleteCommonAreaPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "UPDATE_APPOINTMENT_COMMONAREA_STATUS":
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.id}/${action.payload.action}`;
          store.dispatch(isLoader(true));
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: action.payload.action === 'complete' ? `${action.payload.action}d` : `${action.payload.action}ed`,
                componentName:'UpdateAppointmentAreaStatus'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to update area status!'
                }
              });
            } else {
              let err = {componentName:'UpdateAppointmentAreaStatus',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "UPDATE_APPOINTMENT_COMMONAREA_INSPECTION_STATUS":
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.id}/inspection/${action.payload.action}`;
          store.dispatch(isLoader(true));
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: `Inspection ${action.payload.action}ed.`,
                componentName:'UpdateAppointmentAreaInspectionStatus'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to update area status!'
                }
              });
            } else {
              let err = {componentName:'UpdateAppointmentAreaInspectionStatus',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "EXPORT_COMMON_AREA_APPTS":
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataWithParams(action.payload.exportApi,"")
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Common Area Appointments"}.xlsx`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'danger',
                    message: 'Unable to Download Excel Report!',
                }
              });
            } else {
              let err = {componentName:'DownloadCommonAreaApptXlsx',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
        break;

        case "PRINT_COMMON_AREA_APPTS":
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataWithParams(action.payload.printApi,"")
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Common Area Appointments"}.pdf`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                      variant:'danger',
                      message: 'Unable to Download Report!',
                  }
                });
              } else {
                let err = {componentName:'DownloadCommonAreaApptReportPdf',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
        break;

        case "DELETE_COMMON_AREA_ITEMS" : 
          endpoint = microServices.commonarea+ action.payload.endpoint;
          store.dispatch(isLoader(true));
          APIservice
          .put(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: action.payload.message,
                componentName:action.payload.componentName,
                ca_templates_area_id : action.payload.ca_templates_area_id
              }
            });
          })
          .catch((error)=>{
            console.log(error.response?.data?.ca_templates_items_id[0]);
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'danger',
                message: error.response?.data?.ca_templates_items_id?.length ? error.response?.data?.ca_templates_items_id[0] : ''
              }
            });

          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "START_ITEM" :
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.area_id}/item/${action.payload.item_id}/start`;
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Item started successfully.',
                componentName:'StartItem'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to start item!'
                }
              });
            } else {
              let err = {componentName:'StartItem',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          break;

        case "COMPLETE_ITEM" :
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.area_id}/item/${action.payload.item_id}/complete`;
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Item completed successfully.',
                componentName:'CompleteItem'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to complete item!'
                }
              });
            } else {
              let err = {componentName:'CompleteItem',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          break;
        case "PASS_ITEM" :
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.area_id}/item/${action.payload.item_id}/inspection/pass`;
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Item passed successfully.',
                componentName:'PassItem'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to pass item!'
                }
              });
            } else {
              let err = {componentName:'PassItem',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          break;
        case "FAIL_ITEM" :
          endpoint = `${microServices.commonarea}/v1/appointment/area/${action.payload.area_id}/item/${action.payload.item_id}/inspection/fail`;
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Item failed successfully.',
                componentName:'FailItem'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to fail item!'
                }
              });
            } else {
              let err = {componentName:'FailItem',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          break;

        default:
            break;
        }
        return result;
      };
      
  export default CommonAreaMiddleware;

  const prepareCommonAreaDropdown = (response) => {
        
        let ActiveCommonAreas = [];
        let InActiveCommonAreas = [];
        let CommonAreaList = [{
            label: "Active",
            options: ActiveCommonAreas,
        },
        {
            label: "Inactive",
            options: InActiveCommonAreas,
        },
    ];
    response.data.data.map((data) => {
        if (data.Status.status_id == '1') {
            ActiveCommonAreas.push({
            value: data.common_area_id,
            label: data.common_area_name,
            status : 1
        });
        }
    else if (data.Status.status_id == '2') {
        InActiveCommonAreas.push({
        value: data.common_area_id,
        label: data.common_area_name,
        status : 2
      });
    }
  });
  return CommonAreaList;
}

const prepareFilterUrl = (filters) => {
  let searchUrl = '';

  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      searchUrl += `filter[${key}]=${value}&`
    }
  }
  return searchUrl;
}

const prepareCommonAreaTemplatesDropdown = (response) => {
  let active = [];
  let inactive = [];
  response.data.data.map((data) => {
    if (data.status.status_id == '1') {
      active.push({
        value: data.common_area_template_id,
        label: data.title,
        common_area_id: data.common_area_id,
        status : 1
      });
    }
    else if (data.status.status_id == '2') {
      inactive.push({
        value: data.common_area_template_id,
        label: data.title,
        common_area_id: data.common_area_id,
      });
    }
  })
  let list = [{
      label: "Active",
      options: active,
    },
    {
        label: "Inactive",
        options: inactive,
    },
  ];
  return list;
}
 
const prepareCommonAreaStaff = (response) => {
  let ActiveCommonAreaStaffs = [];
  let InActiveCommonAreaStaffs = [];
  let CommonAreaList = [{
      label: "Active",
      options: ActiveCommonAreaStaffs,
  },
  {
      label: "Inactive",
      options: InActiveCommonAreaStaffs,
  }];

  response.data.data.map((data) => {
    if (data.Status.status_id == '1') {
      ActiveCommonAreaStaffs.push({
        value: data.user_id,
        label: data.name,
        status : 1
      });
    }
    else if (data.Status.status_id == '2') {
      InActiveCommonAreaStaffs.push({
        value: data.user_id,
        label: data.name,
        status : 2
      });
    }
  });
  return CommonAreaList;
}