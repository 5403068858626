import React, { useContext } from 'react';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Text, LanguageContext } from '../../containers/Language';
import { FiX } from "react-icons/fi";
import { useSelector } from 'react-redux';

const CustomModal = React.forwardRef(({ children, backdrop, show, handleClose, title, size, handleSubmit, validated, errorReset, className, photoPoup, handleSave, useHandleSave,
  setPrimaryBtnTitle, setBtnTitle, showAnotherIcon, iconClick,
  viewEditReportBtns, viewCompleteInspectionBtns, dangerVariant, successVariant, setDangerTitle, setSuccessTitle,setCancelTitle, handleRequestCallback, setRequestCallbackTitle,
  handleCallbackAssign, handleCompleteInspection, handleCancelInspection , setAssignHousekeeperTitle, handleAssignHousekeeper, isCallbackDisabled, isFacility, isCompleted, isPropertyInspection,
  AppointmentInspection = false, isAssignHousekeeperCallback = false, housekeeperType, setCallbackAssignTitle, addSubtaskSave, addSubtaskBtnTitle,
  addSubtaskModalBtns = false, isShowCreateAndAssignButton, removeCloseBtn, autopayModalBtns, handleDecline, autopayBtnTitle, autopayBtnRemainderTitle,
  handleRemainder, scheduleStaffModalBtns, addStaffShiftBtnTitle, handleAddStaffShift, handleSaveShift, saveShiftBtnTitle, addStaffBtnTitle, scheduleAddStaffBtn, handleAddShift,
        saveStaffNextBtns, btnOnClick, showRemindMeLater=false,isApptAndPropertyNotShow=false, removeResetBtn=false, variant, isCancelled = true,isInspectionSchedule =false,enableInspectionTitle,handleEnableInspection, autopayPaymentStaticModalBtns, handleDeactivate,  saveAndSend, handleSaveAndSend,requestSubmit=false,handleLogout,payAgreementModalBtns,isDeclineShow=false,showCloseButton=false,
        invalidPaymentDetailsBtns =false,
      resetBtnTitle = false}, ref) => {
  
  const { localised } = useContext(LanguageContext);
  const isLoading = useSelector((state) => state.global.loading);

  const submitHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (useHandleSave) {
      handleSave(event);
    } else {
      handleSubmit(event);
    }
  }
  return (
    <div>
      <Form validated={validated} id='my-form' onSubmit={submitHandler} noValidate >
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={backdrop}
          keyboard={false}
          scrollable={true}
          size={size}
          className={className}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            {(removeCloseBtn === true) &&
              <span className='modal-close-btn' onClick={handleClose}><FiX /></span>
            }
          </Modal.Header>
          <Modal.Body>
            {children}
          </Modal.Body>
          {(photoPoup === true) && <Modal.Footer>
            {!removeResetBtn && <Button variant="secondary" form='my-form' type="reset" onClick={errorReset}>
              {localised.reset}
            </Button>
            }
            <Button disabled={isLoading} variant={variant ? variant : "primary"} form='my-form' type="submit">
              {(isLoading) ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : setPrimaryBtnTitle || localised.save}
            </Button>
            {
              (addSubtaskModalBtns && isShowCreateAndAssignButton && !isApptAndPropertyNotShow) &&
              <>
                <Button variant="primary" onClick={addSubtaskSave} type="submit"> {addSubtaskBtnTitle}</Button>
              </>
            }
          </Modal.Footer>

          }

          {
            viewEditReportBtns &&
            <Modal.Footer>
              <Button variant={successVariant} onClick={handleCompleteInspection}> {setSuccessTitle}</Button>
              <Button variant={dangerVariant} onClick={handleCancelInspection}> {setCancelTitle}</Button>
              <Button variant={dangerVariant} onClick={handleCallbackAssign}> {setDangerTitle}</Button>
              <Button variant={dangerVariant} onClick={handleRequestCallback}> {setRequestCallbackTitle}</Button>
              <Button variant={dangerVariant} onClick={handleAssignHousekeeper}> {setAssignHousekeeperTitle}</Button>
            </Modal.Footer>
          }
          {
            viewCompleteInspectionBtns &&
            <Modal.Footer>
              {
                (isInspectionSchedule) && 
                <Button variant='primary' onClick={handleEnableInspection}> {enableInspectionTitle} </Button>
              }
              {(!isCompleted) &&
                <Button variant={successVariant} onClick={handleCompleteInspection}> {setSuccessTitle} </Button>
              }
              {(!isCancelled) &&
                <Button variant={dangerVariant} onClick={handleCancelInspection}> {setCancelTitle}</Button>
              }
              {(housekeeperType) &&
                (!AppointmentInspection && !isFacility && !isPropertyInspection && !isCompleted) &&
                <>
                  {(!isCallbackDisabled) &&
                    <Button variant={dangerVariant} onClick={handleRequestCallback}> {setRequestCallbackTitle}</Button>}
                  {(!isAssignHousekeeperCallback && isCallbackDisabled) &&
                    <Button variant={dangerVariant} onClick={handleAssignHousekeeper}> {setAssignHousekeeperTitle}</Button>
                  }
                  {(isAssignHousekeeperCallback && isCallbackDisabled) &&
                    <Button variant={dangerVariant} onClick={handleRequestCallback} disabled> {setCallbackAssignTitle}</Button>}
                </>
              }
            </Modal.Footer>
          }


          {
            autopayModalBtns &&
            <>
              <Modal.Footer>
                <Button variant="primary" onClick={handleSave} > {setPrimaryBtnTitle || localised.save}</Button>
                <Button variant="danger" onClick={handleDecline} > {autopayBtnTitle}</Button>
              {showRemindMeLater && 
                  <Button variant="warning" onClick={handleRemainder} > {autopayBtnRemainderTitle}</Button>
              }  
              </Modal.Footer>
            </>
          }

          {
            scheduleStaffModalBtns &&
            <>
              <Modal.Footer>
                <Button variant="success" onClick={handleAddStaffShift} > {addStaffShiftBtnTitle}</Button>
              </Modal.Footer>
            </>
          }
          {
            saveStaffNextBtns &&
            <Modal.Footer>
              <Button variant="success" onClick={handleSaveShift} > {saveShiftBtnTitle}</Button>
            </Modal.Footer>
          }
          {
            scheduleAddStaffBtn &&
            <Modal.Footer>
              <Button variant="success" onClick={handleAddShift} > {addStaffBtnTitle}</Button>
            </Modal.Footer>

          }
          {btnOnClick &&
            <>
              <Modal.Footer>
                <Button variant="secondary" form='my-form' type="reset" onClick={errorReset}>
                  {(resetBtnTitle) ? resetBtnTitle : localised.reset}
                </Button>
                <Button  variant={variant ? variant : "primary"}  onClick={handleSave} disabled={isLoading}> 
                {(isLoading) ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                ) : setPrimaryBtnTitle || localised.save}
                </Button>
              </Modal.Footer>
            </>                
          }

{
            autopayPaymentStaticModalBtns &&
            <>
              <Modal.Footer>
              <Button variant="danger" onClick={handleDeactivate}  disabled={requestSubmit}> {localised.deactivateAccount}</Button> 
              <Button variant="secondary" onClick={handleLogout} > {localised.signOut}</Button> 
              <Button variant="primary" onClick={handleSave} disabled={requestSubmit}>{localised.addPaymentDetail} </Button>
              </Modal.Footer>
             
             
            </>
          }


          {
            invalidPaymentDetailsBtns &&
            <>
              <Modal.Footer>
              <Button variant="primary" onClick={handleSave} disabled={requestSubmit}>{localised.addPaymentDetail} </Button>
              <Button variant="danger" onClick={handleClose}  disabled={requestSubmit}> {localised.close}</Button> 
              </Modal.Footer>
             
             
            </>
          }
        {saveAndSend &&
          <Modal.Footer>
            <Button variant="secondary" disabled={isLoading} onClick={handleSaveAndSend}>
            {(isLoading) ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              ) : localised.saveAndSend}
            </Button>
            <Button variant="primary" disabled={isLoading} onClick={handleSave}> 
              {(isLoading) ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              ) : localised.save}
            </Button>
          </Modal.Footer>
        }
          {
            payAgreementModalBtns &&
            <>
              <Modal.Footer>
                <Button variant="primary" onClick={handleSave} >{localised.agreeAndContinue}</Button>
                {isDeclineShow && <Button variant="danger" onClick={handleDecline} > {localised.decline}</Button> }
              </Modal.Footer>
            </>
          }

          {requestSubmit && 
            <Modal.Footer>
              Deactivate Account Request has Initiated !!
            </Modal.Footer>
          }
          {showCloseButton && 
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose} >{localised.close}</Button>
            </Modal.Footer>
          }
        </Modal>
      </Form>
    </div>
  )
});
CustomModal.defaultProps = {
  photoPoup: true,
  removeCloseBtn: true
}
export default CustomModal;