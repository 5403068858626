import APIservice from "../apiService";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * ApiMiddleware can be used to call any Service related API services
 * @param {*} store
 * @returns
 */
const StaffPlannerMiddleware = (store) => (next) => async (action) => {
  const result = next(action);
  const endpoint = microServices.base + "/v1/company/staff-planner";
  switch (action.type) {
    case "UPDATE_APPOINTMENT_STAFF_TIME":
      APIservice.put(
        endpoint + "/time/" + action.payload.appointmentStaffId,
        action.payload.formData
      )
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
              componentName: "updateAppointmentStaffTime",
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: action.payload.failMessage,
              },
            });
          } else {
            let err = {
              componentName: "updateAppointmentStaffTime",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
        });
      break;
      case "UPDATE_PROPERTY_INSPECTION_TIME":
      APIservice.put(
        endpoint + "/inspection/time/" + action.payload.appointmentStaffId,
        action.payload.formData
      )
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
              componentName: "updateAppointmentStaffTime",
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: action.payload.failMessage,
              },
            });
          } else {
            let err = {
              componentName: "updateAppointmentStaffTime",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
        });
      break;
      
      default:
      break;
  }
  return result;
};

export default StaffPlannerMiddleware;
