import apiService from "../redux/apiService";
import { Grid } from 'gridjs-react';

export function setGrid(
        endpoint, 
        columns = [], 
        perPage = 0, 
        paginationEnabled = true, 
        className  = { container: "table-wrapper" }, 
        currentRef = null, 
        refreshComponent = false,
        setGridResponse = null,
        setLatestSort = null,
        latestSort=null,
        clientSort = false) {

    if(endpoint.includes('&sortByStaff=') && endpoint.includes('&sort=')) {
        let _startIndex = endpoint.indexOf('&sort=');
        let _str = endpoint.substr(_startIndex, endpoint.lastIndexOf(endpoint.substr((_startIndex+1), endpoint.indexOf('&')-1)));
        endpoint = endpoint.replace(_str, "");
    }

    let gridObj = new Grid({
        columns: columns,
        pagination: {
            enabled: paginationEnabled,
            limit: perPage,
            server: {
                url: (prev, page, limit) => {
                    page = page + 1;
                    if (prev.indexOf("?") > -1) {
                        return `${prev}&per_page=${limit}&page=${page}`;
                    }
                    return `${prev}?per_page=${limit}&page=${page}`;
                }
            }
        },
        autoWidth: true,
        sort: (clientSort) ? true : {
            multiColumn: false,
            server: {
                url: (prev, cols) => {
                    let url = prev;
                    if (!cols.length) return url;
                    const col   = cols[0];
                    let colName = columns[col.index].sort_by;
                    if(url.includes('&sort=') && latestSort!=null) {
                        let newUrl = url;
                        url = newUrl.replace('&sort='+latestSort,"");
                    }
                    if(url.includes('&sortByStaff=')) {
                        let _str = url.substr(url.indexOf('&sortByStaff='), url.lastIndexOf('&sortByStaff=')+1);
                        url = url.replace(_str, "");
                    }
                    const sort  = col.direction === 1 ? colName : colName.includes(',') ? `-${colName.replace(',',',-')}` : `-${colName}`;
    
                    if (setLatestSort) setLatestSort(sort);
                    if (url.indexOf("?") > -1) {
                        return `${url}&sort=${sort}`
                    }
                    return `${url}?sort=${sort}`
                }
            },
        },
        resizable: true,
        className: className,
        server: {
            url: endpoint,
            method: 'GET',
            body: {},
            async data(options) {
                return await getGridData(options.url, setGridResponse);
            },
        }
    });

    if(currentRef){
        gridObj.render(currentRef);
    }

    gridObj.props.refreshComponent = refreshComponent;
    return gridObj;
}

function getGridData(endpoint, setGridResponse) {
    let gridData = { data : [], total: 0, limit: 0, meta :[] }
    return apiService
        .get(endpoint)
        .then((response) => {
            if (response && response.data) {
                if (response.data.data && response.data.data.length) {
                    response.data.data = response.data.data.map((data) => {
                        if(data.first_name || data.last_name){
                            data['name'] = (data.last_name) ? `${data.first_name} ${data.last_name}` : data.first_name;
                        }
                        return data;
                    })
                }
                
                if(setGridResponse) setGridResponse(response.data);
                gridData.data   = response.data.data;
                gridData.data['meta'] = (response.data && response.data.meta) ? response.data.meta : {};
                gridData.total  = response.data.meta.total;
                gridData.limit  = response.data.meta.per_page;
                gridData.meta   = response.data.meta;
                return gridData;
            } else {
                return gridData;
            }
        })
        .catch((error) => {
            return gridData;
        });
}

export function setGridFilter(endpoint, filters) {
    let searchUrl = '';
    if (filters) {
        for (const key in filters) {
            if(filters?.hasOwnProperty(key) && filters[key] && typeof(filters[key]) === "object") {
                var objLength = filters[key].length;
                if(objLength>0){
                    searchUrl += `filter[${key}]=`
                    for(var i=0; i<objLength; i++){
                        if(i<objLength-1){
                            searchUrl += `${filters[key][i]['value']},`;
                        }else{
                            searchUrl += `${filters[key][i]['value']}`;
                        }
                    }
                    searchUrl += `&`
                }
            }
            else if (filters?.hasOwnProperty(key) && ((filters[key] && filters[key] !== '') || filters[key] === 0)) {
                searchUrl += `filter[${key}]=${filters[key]}&`;
            }
        }

        if (searchUrl.charAt(searchUrl.length - 1) === '&') {
            searchUrl = searchUrl.substr(0, searchUrl.length - 1);
        }
        
        return `${endpoint}?${searchUrl}`;
    }
} 

export const getGridServerConfig = (endpoint, setData = null) => ({
    url: endpoint,
    method: 'GET',
    async data(options) {
        const data = await getGridData(options.url);
        if (setData) setData(data);
        return data;
    }
});

export const getGridPaginationConfig = (enabled = true, perPage = 10) => ({
  enabled: enabled,
  limit: perPage,
  server: {
    url: (prev, page, limit) => {
      page = page + 1;
      if (prev.indexOf("?") > -1) {
        return `${prev}&per_page=${limit}&page=${page}`;
      }
      return `${prev}?per_page=${limit}&page=${page}`;
    }
  }
});

export const getGridSortConfig = (columns) => ({
    multiColumn: false,
    server: {
        url: (prev, cols) => {
            let url = prev;
            if (!cols.length) return url;
            
            const col = cols[0];
            let colName = columns[col.index].sort_by;
            
            const sort = col.direction === 1 ? colName : colName.includes(',') ? `-${colName.replace(',', ',-')}` : `-${colName}`;

            if (url.indexOf("?") > -1) {
                return `${url}&sort=${sort}`
            }
            
            return `${url}?sort=${sort}`
        }
    },
});

export async function getNoramlGridData(endpoint) {
    return await getGridData(endpoint);
}
