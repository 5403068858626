const initState = {
  loading: false,
  cleanerAssignmentTrackingInformation : []
}

const company = (state = initState, action) => {

  switch (action.type) {
    case "SET_CLEANER_ASSOCIATES": 
      return {
        ...state,
        cleanerAssociates: action.payload,
      };
    case "SET_MANAGER_ASSOCIATES":
      return {
        ...state,
        managerAssociates: action.payload,
      };
    case "SET_COMPANY_INFO":
      return {
        ...state,
        companyInfo: action.payload.data,
      };

    case "CLEAR_COMPANY_INFO":
      return {
        ...state,
        companyInfo: null
      };

    case "SET_COMPANY_PHOTOS":
      return {
        ...state,
        photos: action.payload.data,
      };

    case "CLEAR_COMPANY_PHOTOS":
      return {
        ...state,
        photos: null,
      };
  
    case "SET_PAYPAL_ADDRESS":
      return {
        ...state,
        paypal: action.payload.data,
      };

      case "SET_PROFILE_PICTURE": 
      return {
        ...state,
        profilePicture: action.payload,
      };

      case "SET_COMPANY_SETTING":
      return {
        ...state,
        companySetting: action.payload.data[0],
      };

      case "SET_INVOICE_SETTING":
      return {
        ...state,
        invoiceSetting: action.payload.data,
      };

      case "CLEAR_INVOICE_SETTING":
      return {
        ...state,
        invoiceSetting: []
      };

      case "SET_APPT_LIST_COMPANY_INFO": 
      return {
        ...state,
        ApptCompInfo: action.payload,
      };

      case "SET_LEFT_SIDEBAR_COUNT":
        return {
          ...state,
          sidebarCount: action.payload.data,
      };
  
      case "CLEAR_LEFT_SIDEBAR_COUNT":
        return {
          ...state,
          sidebarCount: []
      };

      case "SET_SCHEDULE_STAFF_SETTING": {
        return {
          ...state,
          scheduleStaffSetting: action.payload.data,
        };
      }

      case "CLEAR_SCHEDULE_STAFF_SETTING": {
        return {
          ...state,
          scheduleStaffSetting: null,
        };
      }

      case "SET_EMAIL_SETTING" : {
        return {
          ...state,
          emailSettingList: action.payload.data
        }
      }
       
      case "SET_DROPDOWN_SCHEDULE_STAFF_SHIFTS": {
        return {
          ...state,
          scheduleStaffShiftsDropdown: action.payload.data,
        }
      }

      case "CLEAR_DROPDOWN_SCHEDULE_STAFF_SHIFTS": {
        return {
          ...state,
          scheduleStaffShiftsDropdown: [],
        }
      }

      case "SET_STAFF_DEFAULT_SCHEDULE": {
        return {
          ...state,
          staffDefaultSchedule: action.payload.data,
        }
      }

      case "CLEAR_STAFF_DEFAULT_SCHEDULE": {
        return {
          ...state,
          staffDefaultSchedule: [],
        }
      }

      case "SET_ADD_STAFF_LIST": {
        return {
          ...state,
          addStaffList: action.payload.data,
        }
      }

      case "CLEAR_ADD_STAFF_LIST": {
        return {
          ...state,
          addStaffList: null,
        }
      }

      case "SET_PUSH_NOTIFICATIONS" : {
        return {
          ...state,
          pushNotificationsList: action.payload.data
        }
      }

      case "CLEAR_PUSH_NOTIFICATION" : {
        return {
          ...state,
          pushNotificationsList: null
        }
      }
      case "SET_HEADER_MESSAGE_COUNT":
        return {
          ...state,
          headerCount: action.payload.data,
      };
  
      case "CLEAR_HEADER_MESSAGE_COUNT":
        return {
          ...state,
          headerCount: []
      };

      case "SET_INTEGRATION_CLIENTS": {
        return {
          ...state,
          integrationClients: action.payload.data
        };
      }

      case "CLEAR_INTEGRATION_CLIENTS": {
        return {
          ...state,
          integrationClients: []
        }
      }

      case "SET_INTEGRATION_PROPERTIES": {
        return {
          ...state,
          integrationProperties: action.payload.data
        };
      }

      case "CLEAR_INTEGRATION_PROPERTIES": {
        return {
          ...state,
          integrationProperties: []
        }
      }
      
      case "SET_COPY_PREV_SCHEDULE_STAFF_LIST": {
        return {
          ...state,
          copyPrevScheduleStaffList: action.payload.data,
        }
      }

      case "CLEAR_COPY_PREV_SCHEDULE_STAFF_LIST": {
        return {
          ...state,
          copyPrevScheduleStaffList: [],
        }
      }

      case "SET_VACASA_PM_DROPDOWN": {
        return {
          ...state,
          vacasaPmData: action.payload
        }
      }
      case "CLEAR_VACASA_PM_DROPDOWN": {
        return {
          ...state,
          vacasaPmData: []
        }
      }
      case "SET_QB_CLINTS": {
        return {
          ...state,
          qbClients: action.payload
        }
      }

      case "CLEAR_QB_CLINTS": {
        return {
          ...state,
          qbClients: []
        }
      }

    case "SET_ASSIGNMENT_TRACKING_INFO": {
      return {
        ...state,
        assignmentTrackingInformation: action.payload.data,
      };
    }

    case "CLEAR_ASSIGNMENT_TRACKING_INFO": {
      return {
        ...state,
        assignmentTrackingInformation: []
      }
    }
      case "SET_HK_PROGRESS": {
        return {
          ...state,
          hk_progress: action.payload.data,
          hk_target: action.payload.target
        }
      }

      case "CLEAR_HK_PROGRESS": {
        return {
          ...state,
          hk_progress: []
        }
      }

      case "SET_HA_PROGRESS": {
        return {
          ...state,
          ha_progress: action.payload.data,
          ha_target: action.payload.target
        }
      }

      case "CLEAR_HA_PROGRESS": {
        return {
          ...state,
          ha_progress: []
        }
      }

      case "SET_INSP_PROGRESS": {
        return {
          ...state,
          insp_progress: action.payload.data,
          insp_target: action.payload.target
        }
      }

      case "CLEAR_INSP_PROGRESS": {
        return {
          ...state,
          insp_progress: []
        }
      }

      case 'SET_HK_PERFORM_COUNT':{
        return {
          ...state,
          hkPerformCount : action.payload.data
        };
      }
      
      case 'CLEAR_HK_PERFORM_COUNT':{
        return {
          ...state,
          hkPerformCount : null
        };
      }


      case 'SET_COMPANY_SUBSCRIPTION':{
        return {
          ...state,
          compSubscrption : action.payload.data
        };
      }
      
      case 'CLEAR_COMPANY_SUBSCRIPTION':{
        return {
          ...state,
          compSubscrption : null
        };
      }

      case "CLEAR_MANAGER_ASSOCIATES":{
        return {
          ...state,
          managerAssociates: null,
        };
      }

      case "SET_PMLOCK_MANAGER_ASSOCIATES":
        return {
          ...state,
          pmLockAssociates: action.payload,
        };

      case "CLEAR_PMLOCK_MANAGER_ASSOCIATES":
        return {
          ...state,
          pmLockAssociates: null
        };
        case "SET_DROPDOWN_PROPELR_TEMPLATE":
          return {
            ...state,
            propelrTemplate: action.payload,
          };
  
        case "CLEAR_DROPDOWN_PROPELR_TEMPLATE":
          return {
            ...state,
            propelrTemplate: null,
          };  
        
        
      case "CLEAR_COMPANY_SETTING":
      return {
        ...state,
        companySetting: [],
      };  

      case "SET_WORKORDER_ASSIGNMENT_TRACKING_HK_TARGET": {
        return {
          ...state,
          workorderAssignmentTrackingHkTarget: action.payload.data?.target || 0,
        }
      }
      case "CLEAR_APPT_LIST_COMPANY_INFO": 
      return {
        ...state,
        ApptCompInfo: [],
      };

      case "SET_INTEGRATION_PROPERTY_LIST_DROPDOWN": {
        return {
          ...state,
          propertiesDropdowns: action.payload
        }
      }

      case "CLEAR_INTEGRATION_PROPERTY_LIST_DROPDOWN": {
        return {
          ...state,
          propertiesDropdowns: []
        }
      }

      case "SET_CLEANER_ASSIGNMENT_TRACKING_INFO": {
        return {
          ...state,
          cleanerAssignmentTrackingInformation: action.payload.data,
        };
      }

      case "SET_ADD_STAFF_PLANNER_LIST":{
        return {
          ...state,
          addStaffList: action.payload.data
        }
      }
  
    default:
      return state;
  }
};

export default company;
