
export const futureDay = [
    { value: 0, label: 'Today Only' },
    { value: 1, label: ' Today + 1 (Tomorrow)' },
    { value: 2, label: ' Today + 2' },
    { value: 3, label: ' Today + 3' },
    { value: 7, label: ' Today + 7' },
    { value: 14, label: ' 14 days' },
    { value: 31, label: ' 31 days' },
    { value: 365, label: ' 365 days' },
];

export const statusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Completed" },
    { value: 3, label: "Cancelled" },
    { value: 4, label: "In Progress" },

];

export const typeOptions = [
    { value: 1, label: "Appointment" },
    { value: 2, label: "Appointment" },
    { value: 3, label: "Property" },
    { value: 4, label: "Facility" },
];

export const ivrstatusOptions = [
    { value: 1, label: "No Activity" },
    { value: 2, label: "Checked In" },
    { value: 3, label: "Checked Out" },
    { value: 4, label: "Completed (inspection pending)" },
    { value: 5, label: "Completed" },
    { value: 2, label: "CB In" },
    { value: 3, label: "CB Out" },
];

export const yesNoOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
];

export const workorderStatusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Completed" },
    { value: 3, label: "Completed (Invoiced)" },
    { value: 4, label: "Cancelled" },
    { value: 5, label: "Reviewed"},
    { value: 6, label: "Unconfirmed"}
];

export const autoPayStatusOptions = [
    
    { value: 1, label: "All", status: [] },
    {
        value: 2, label: "Enabled/Profile Saved (Unverified)",
        status: [
            { enable_autopay: true }, { autopay_is_verified: 'false' }, { has_customer: true }
        ]
    },
    {
        value: 3, label: "Enabled/Profile Saved (Verified)",
        status: [{ enable_autopay: true }, { autopay_is_verified: true }, { has_customer: true }]
    },
    {
        value: 4, label: "Enabled/Profile Incomplete ",
        status: [{ enable_autopay: true }, { has_customer: 'false' }]
    },
    {
        value: 5, label: " Disabled ",
        status: [{ has_disable: true }]
    },
    {
        value: 6, label: "Decline/Disabled ",
        status: [{ is_autpay_decline: true }, { enable_autopay: 'false' }]
    },
    {
        value: 7, label: "Disabled/Profile Saved ",
        status: [{ enable_autopay: 'false' }, { has_customer: true }]
    },
];


export const autoPayBeraredBy = [
    { value: 1, label: 'CL' },
    { value: 2, label: 'PM' }
]

export const TILLED_ID = 3;
export const  PAYPAL_ID = 1;
export const  STRIPE_ID = 2;

export const paymentModeOption = [
    { value: 0, label: 'Per/Appointment', },
    { value: 1, label: 'Per/Property' },
];

export const selectInvoiceOptions = [
    { value: 2, label: 'Property Managers', text: 'manager'},
    { value: 3, label: 'Cleaner', text: 'cleaner' },
];

export const cleanerAdminSideObj = {
    userType: 'cleaner',
    per_page : 20,
    filter: {
      status_id: '1,2',
    }
  }
  
export const managerAdminSideObj = {
    userType: 'property-manager',
    per_page : 20,
  }


export const everyOptions = [
    { value: 1, label: 'Daily' },
    { value: 2, label: 'Weekly' },
    { value: 3, label: 'Monthly' },
    { value: 5, label: 'Quarterly' },
    { value: 4, label: 'Yearly' },
];



export const dateOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
    { value: 24, label: '24' },
    { value: 25, label: '25' },
    { value: 26, label: '26' },
    { value: 27, label: '27' },
    { value: 28, label: '28' },
    { value: 29, label: '29' },
    { value: 30, label: '30' },
    { value: 31, label: '31' },
];

export const dayOptions = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' },
];

export const repeatWeeklyOptions = [
    { value: 1, label: '1 Week' },
    { value: 2, label: '2 Weeks' },
    { value: 3, label: '3 Weeks' },
    { value: 4, label: '4 Weeks' },
];

export const repeatYearlyOptions = [
    { value: 1, label: 'Annually' },
    { value: 2, label: 'Semi-Annually' },
];

export const payModeOptions = [
    { value: 1, label: 'CL Pays' },
    { value: 2, label: 'PM Pays' },
];

export const serviceTypeOptions = [
    { value: 0, label: 'Outside Services' },
    { value: 1, label: 'Inside Services' },
];

export const defaultApmtStatus =  [
    {value: 1, label: 'Unconfirmed'}, 
    {value: 2, label: 'confirmed'},
    {value: 3, label: 'Completed'},
    {value: 4, label: 'Completed (Invoiced)'}
];

export const  dndStatus =  [
    {value: 1, label: 'dnd'}, 
    {value: 2, label: 'vacant'}
];

export const WorkorderTypeOptions = [
    { value: 0, label: "Outside" },
    { value: 1, label: "Internal" }
];

export const RCInvoiceinitialStatus = [
    { value: 2, label: "Unpaid" },
    {value: 4, label: 'Partially Paid'},
    {value: 6, label: 'Past Due'},
    {value: 7, label: 'Pending'},
    {value: 8, label: 'Declined'},
    {value: 9, label: 'In Progress'}
];

export const inventorySmartRequestedStatus = [
    { value: 0, label: "Unassigned" },
    { value: 1, label: "Assigned" },
    { value: 2, label: "In Progress" },
    { value: 3, label: "Completed" },
    { value: 4, label: "Cancelled" }
];
export const clObj = {
    userType: 'cl',
    from:'payroll',
    filter: {
      status_id: '1,2',
    }
  }
  
  export const mnObj = {
    userType: 'pm',
    from:'payroll',
    filter: {
      status_id: '1,2',
    }
  }

  export const retryUrls = [
      {url : 'me' , componentName :'LoginError', errorMessage:'Something went wrong'}
  ];

  export const monthOptions = [
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'May' },
    { value: 6, label: 'Jun' },
    { value: 7, label: 'Jul' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sep' },
    { value: 10, label: 'Oct' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dec' },
];

export const durationOptions = [
    { value: 30, label: "30 days" },
    { value: 60, label: "60 days" },
    { value: 90, label: "90 days" },
    { value: 120, label: "120 days" },

];
export const pmCompleteOutsideAppointmentCompanies = [
    10889,
    14865,
    79408,
    15043,
    79442,
    88735,
    3426,
    92449
];

export const createInvoiceWorkorderStatusOptions = [
    { value: 2, label: "Completed" },
    { value: 5, label: "Reviewed"}
];

export const AppointmentInventoryReport = [
    10889,
    3011
];

export const nonEditableFields = [
    'property_name',
    'arrival_date',
    'departure_date',
    'cleaner',
    'turn_around',
    'appointment_service',
    'appointment_id',
    'next_arrival_date'
];

export const oscarUsers = [
    15023,
    26736
];

export const dateFormatOptions = [
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },

];
export const supportedDateFormatOptions = {
    "MM/DD/YYYY": "MM/dd/yyyy",
    "DD/MM/YYYY": "dd/MM/yyyy" ,
   "YYYY/MM/DD": "yyyy/MM/dd",

};
export const  helpEmailID = 'help@resortcleaning.com';

export const helpEmailTo = "mailto:" + helpEmailID;

export const editableCheckInCheckoutTime = [
    26265,
    25893,
    27013,
    91623,
    92826,
    92686,
    90381
];

export const PropertyTypesBronzeGoldSilver = [
    10889
];

export const excludedPropertyTypes = ['gold', 'silver', 'bronze'];
export const enabledintegrationRollFutureAppt = [
    23,27,8,11,12,22,1,2,3,4,5,6,7,17,13,18,19,21,15,14,20,26,28,24,35,36,38,40,39,41,43,44,45
];

export const repeatMonthWiseWeekOptions = [
    { value: 1, label: '1st Week' },
    { value: 2, label: '2nd Week' },
    { value: 3, label: '3rd Week' },
    { value: 4, label: '4th Week' },
];


export const wizardStatusDisplayIds  = [
    1,2
];

export const propertyPriceExportDefaultColumn = [
    'property_name',
    'service_name',
    'price',
    'is_default',
];

export const AdditionalFeatureAccess = {
    INSPECTIONS: 1,
    WORKORDERS: 2,
    HOUSE_ATTENDANT: 3,
    DO_NOT_DISTURB: 7,
    SERVICE_AUTOMATION: 10,
    NOTES_SUBTASKS: 11,
    WEEKLY_EVALUATION_EMAIL: 12,
    SERVICE_CHECKLIST: 13,
    TIMECLOCK: 15,
    SCHEDULE_STAFF: 16,
    INVENTORY_SMART: 17,
    INTERNAL_SERVICES: 18,
    PM_INTERNAL_WORKORDER: 19,
    PM_INTERNAL_SUBTASK: 20,
    SERVICE_REQUEST: 21,
    MY_PAY: 29,
    INSPECTION_TIME_TRACK: 30,
    SERVICE_TIME: 31,
    ASSIGNMENT_APPROVAL: 34,
    OFFLINE_APP_READ_ONLY: 36,
    INVENTORY: 40,
};
export const hhsCompanyId = 24792;
export const bitsClCompanyId = 25893

export const inactivateUserCompanyIds = [
    hhsCompanyId,
    bitsClCompanyId
];

export const inventoryReportFormatexcludedColumns = [
    'properties_id', 'property_name', 'arrival_date', 'departure_date',
    'appointment_id', 'turn_around', 'appointment_service'
];

export const FreeTrialFeature = {
  DISMISS_STATUS_FIRST_THREE_DAY_ALERT: 7,
  DISMISS_STATUS_SEVEN_DAYS_REMAINING: 3,
  DISMISS_STATUS_THREE_DAYS_REMAINING: 1,
  DISMISS_STATUS_ONE_DAY_REMAINING: 0,
  DAYS_THRESHOLD_SEVEN_DAYS: 7,
  DAYS_THRESHOLD_THREE_DAYS: 3,
  DAYS_THRESHOLD_ONE_DAY: 1,
}

export const rcGatewayOption = [
    { value: 1, label: "Tilled" },
    { value: 2, label: "Propelr" },
];

export const guestyServiceMapUsers = [
    12945,
    87407,
    87405,
    3011
];