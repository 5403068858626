import apiService from '../../apiService';
import { microServices } from '../../../config/global';

// Action Types
const FETCH_SCHEDULES_REQUEST = 'FETCH_SCHEDULES_REQUEST';
const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';
const FETCH_SCHEDULES_FAILURE = 'FETCH_SCHEDULES_FAILURE';

// Action Creators
export const fetchSchedulesRequest = () => ({ type: FETCH_SCHEDULES_REQUEST });
export const fetchSchedulesSuccess = (data) => ({ type: FETCH_SCHEDULES_SUCCESS, payload: data });
export const fetchSchedulesFailure = (error) => ({ type: FETCH_SCHEDULES_FAILURE, payload: error });

// Thunk for fetching appointment message schedules
export const fetchAppointmentMessageSchedules = () => async (dispatch, getState) => {
  const { data } = getState().appointmentMessageSchedules;
  
  if (data) {
    return; // Data is already cached
  }

  dispatch(fetchSchedulesRequest());
  try {
    const response = await apiService.get(
      `${microServices.base}/v1/appointment/messaging/schedules`
    );
    dispatch(fetchSchedulesSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchSchedulesFailure(error.message));
  }
};

// Initial state
const initialState = {
  data: null,
  loading: false,
  error: null,
};

// Reducer
const appointmentMessageSchedules = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SCHEDULES_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_SCHEDULES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default appointmentMessageSchedules;
