import React, { useContext } from 'react';
import {Dropdown, Modal, Spinner} from 'react-bootstrap';
import Buttons from '../Buttons';
import { PropTypes } from 'prop-types';
import { FiAlertCircle, FiX } from "react-icons/fi";
// import Style from './styles.scss';
import { Text, LanguageContext } from '../../containers/Language';

const ConfirmModal = React.forwardRef((props, ref) => {
  const { localised } = useContext(LanguageContext);
  return (
    <Modal
      show={props.showconfirm}
      backdrop="true"
      handlecloseconfirm={false}
      keyboard={false}
      onHide={props.handlecloseconfirm}
      size="sm"
      centered
      className={"confirm-modal" + ' ' + props.customClass}
      {...props}
    >
      {props.showTitle && (
        <Modal.Header closeButton>
          <Modal.Title>{props.titleText ?? "Modal heading"}</Modal.Title>
        </Modal.Header>
      )}
      {/* <Modal.Header> </Modal.Header> */}
      <Modal.Body className={`${props.alignLeft ? 'text-start' : 'text-center'}`}>
        {/* <h4 className="text-start mb-0">Confirm</h4> */}
        {
          !props.hideIcon && (
            <div className='icon text-danger'>
              <FiAlertCircle/>
            </div>
          )
        }
        {/* <div className='icon text-danger'>
          <FiAlertCircle/>
        </div> */}
        {
          !props.hidePlainTextMessages &&
          (
            <div className='p-4'>
              {!props?.hidePrefixOfMessages && localised.areYouSureYouWantTo} {props.message} {!props?.hidePrefixOfMessages && '?'}
            </div>
          )
        }
        {
          props.hidePlainTextMessages &&
          (
            <div className={`${props.alignLeft ? 'p-1 region-area-popup' : 'p-4'}`}
              dangerouslySetInnerHTML={{
                __html: `${props.message}`
              }}>
            </div>
          )
        }
        {props.children ?? ""}
        {!props.hideDisplayBtns && <div className="footer-modal justify-content-center text-center mt-2 mb-2">
          <Buttons
            variant="secondary"
            onClick={props.handlecloseconfirm}
            className="me-3"
          >
           {props?.cancelButtonText ?? localised.no}
          </Buttons>
          <Buttons variant="primary" onClick={props.onSubmit} disabled={props.isLoading}>
            {
              props.isLoading && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            }
            { props.isLoading ? null : props.buttontext }
          </Buttons>
        </div>}
        {
          (props.isCloseButton && props.hideDisplayBtns) && <div className="footer-modal justify-content-center text-center mt-2 mb-2">
          <Buttons
            variant="secondary"
            onClick={props.handlecloseconfirm}
            className="me-3"
          >
           { localised.close}
          </Buttons>
        
        </div>}
        
      </Modal.Body>
      {/* <Modal.Footer className="justify-content-center"> </Modal.Footer> */}
    </Modal>
  );
});

ConfirmModal.propTypes = {
  showalert: PropTypes.boolean,
  handlecloseconfirm: PropTypes.func,
  customClass: PropTypes.string, 
  hideIcon: PropTypes.bool,
  showTitle: PropTypes.bool,
  titleText: PropTypes.string,
  alignLeft: PropTypes.bool 
};
export default ConfirmModal;
