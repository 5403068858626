import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import {prepareInventoryTemplatesDropdownOptions} from "../../utils/inventoryUtil";
/**
 * ApiMiddleware can be used to call any Service related API services
 * ApiMiddleware can be used to call any Inventory related API Inventory.
 * @param {*} store 
 * @returns 
 */
const InventoryMiddleware = (store) => (next) => async (action) => {
    const result    = next(action);
    let endpoint  = microServices.base+'/v1/company/inventory';
    let per_page_condition = '';
    switch (action.type) {
        case "CREATE_INVENTORY":
            store.dispatch(isLoader(true));
              APIservice
                .post(endpoint, action.payload.initalizeData)
                .then((response)=>{
                  if(!response.data.data.upload)
                        {
                          store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                              variant:'success',
                              message: 'Inventory created successfully!',
                              componentName:'AddInventory'
                            }
                          });
                      }
                      else{
                        store.dispatch({ 
                          type: 'UPLOAD_INVENTORY_IMAGE',
                          message: 'Inventory created successfully!',
                          componentName:'AddInventory',
                          payload: { 
                            'uploadUrl':response.data.data.upload,
                            'uploadImagedata':action.payload.formData,
                          },
                          })
                      }
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'danger',
                        message: 'Unable to save Inventory information!'
                      }
                    });
                  } else {
                    let err = {componentName:'AddInventory',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
            case "UPLOAD_INVENTORY_IMAGE":
    endpoint = action.payload.uploadUrl;
    store.dispatch(isLoader(true));
    APIservice
    .postMultipartData(endpoint,action.payload.uploadImagedata)
    .then(()=>{
      store.dispatch({ 
        type: 'SHOW_ALERT', 
        payload: {
          variant:'success',
          message: (action?.message) ? action.message : 'Inventory Updated successfully.',
          componentName:(action?.componentName) ? action.componentName : 'EditInventory',
        }
      });
     })
    .catch((error)=>{
        let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
        store.dispatch(setError(err));
    }).finally(()=>{
        store.dispatch(isLoader(false));
    })
    break;
  
        case "UPDATE_INVENTORY":
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.id, action.payload.initalizeData)
            .then((response)=>{
              if(!response.data.data.upload)
                        {
                          store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                              variant:'success',
                              message: 'Inventory updated successfully!',
                              componentName:'EditInventory'
                            }
                          });
                      }
                      else{
                        store.dispatch({ 
                          type: 'UPLOAD_INVENTORY_IMAGE',
                          message: 'Inventory updated successfully!',
                          componentName:'EditInventory',
                          payload: { 
                            'uploadUrl':response.data.data.upload,
                            'uploadImagedata':action.payload.formData,
                          },
                          })
                      }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to save inventory information!',
                  }
                });
              } else {
                let err = {componentName:'EditInventory',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
            break;

            case "DELETE_IMAGE_INVENTORY":
              store.dispatch(isLoader(true));
                APIservice
                  .delete(endpoint+'/image/'+action.payload.inventoryId)
                  .then((response)=>{
                   
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                          message: 'Inventory Image deleted successfully!',
                        }
                      });
                    
                  })
                  .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'danger',
                            message: 'Unable to Delete Inventory Image!',
                          }
                        })}
                        else {
                          let err = {componentName:'deleteImageInventory',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        }
                      })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  });
              break;
        case "DELETE_INVENTORY":
            store.dispatch(isLoader(true));
              APIservice
                .delete(endpoint+'/'+action.payload.inventoryId)
                .then((response)=>{
                 
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'success',
                        message: 'Inventory deleted successfully!',
                      }
                    });
                  
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'danger',
                          message: 'Unable to Delete Inventory!',
                        }
                      })}
                      else {
                        let err = {componentName:'DeleteInventory',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      }
                    })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                });
            break;
          case "FETCH_INVENTORY":
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_DATA_FOR_EDIT', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Inventory',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_ALL_INVENTORIES":
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'?per_page=0')
            .then((response)=>{
              if(response && response.data){
                let inventoryList = [];
                response.data.data.forEach((invlist) => {
                  inventoryList.push({
                    value: invlist.id,
                    label: invlist.name
                  });
                });
                store.dispatch({ 
                  type: 'SET_ALL_INVENTORIES', 
                  payload: {
                    data:inventoryList
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Inventory',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

      case "FETCH_PROPERTY_INVENTORIES":
        store.dispatch(isLoader(true));
        APIservice
          .get(microServices.base + '/v1/property/inventory/' + action.payload.id)
          .then((response) => {
            store.dispatch({
              type: 'SET_PROPERTY_INVENTORIES',
              payload: {
                data: response.data.data
              }
            });
          })
          .catch((error) => {
            let err = { componentName: 'EditProperty', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;

        case "FETCH_PROPERTY_MANAGER_INVENTORIES":
          store.dispatch(isLoader(true));
          APIservice
            .get(microServices.base + '/v1/property/manager/inventory/' + action.payload.id)
            .then((response) => {
              store.dispatch({
                type: 'SET_PROPERTY_MANAGER_INVENTORIES',
                payload: {
                  data: response.data.data
                }
              });
            })
            .catch((error) => {
              let err = { componentName: 'propertyManagerInventory', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          break;

      case "ASSIGN_PROPERTY_INVENTORIES":
        store.dispatch(isLoader(true));
        APIservice
          .post(microServices.base + '/v1/property/inventory/' + action.payload.propertyId, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                message: 'Inventories assigned to property successfully!',
                componentName: 'PropertyInventory'
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: 'Unable to update Inventory!',
                }
              });
            } else {
              let err = { componentName: 'EditInventory', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;
        
      case "EXPORT_INVENTORIES":
        let exportApi = action.payload.exportApi;
        store.dispatch(isLoader(true));
        APIservice.
        getBlobDataWithParams(exportApi,"")
        .then((response)=>{
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Inventory"}.xlsx`);
            document.body.appendChild(link);
            link.click(); 
            link.parentNode.removeChild(link);
        })
        .catch((error)=>{
            if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: 'Unable to Export Inventories Report!',
                    }
                });
            } else {
                let err = {componentName:'InventoryExportReport',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }
        })
        .finally(()=>{
            store.dispatch(isLoader(false));
        });
      break;
      
      case "PRINT_INVENTORIES":
        let printApi = action.payload.printApi;
        store.dispatch(isLoader(true));
        APIservice.
          getBlobDataWithParams(printApi,"")
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Inventory"}.pdf`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
              if (error.response.status !== 422) {
                  store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                          variant:'danger',
                          message: 'Unable to Download Inventories Report!',
                      }
                  });
              } else {
                  let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
      break;
      case "ADD_APPOINTMENT_INVENTORY_NOTE":
        endpoint =
          microServices.base + "/v1/appointment/inventory/note/" + action?.payload?.appointment_id;
        APIservice.put(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "success",
                componentName: 'addInventoryNote',
                message : action?.payload?.successMessage
              },
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "danger",
                  componentName: 'addInventoryNote',
                  message : action?.payload?.failMessage
                },
              });
            } else {
              let err = {
                componentName: 'addInventoryNote',
                statusCode: error.response.status,
                data: error.response.data,
              };
              store.dispatch(setError(err));
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          });
        break;
        case "UPDATE_APPOINTMENT_INVENTORY_BAG_PACKED":
          endpoint =
            microServices.base + "/v1/appointment/inventory/bagPacked/" + action?.payload?.appointment_id;
          APIservice.put(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "success",
                  componentName: 'updateAppointmentInventoryBagPacked',
                },
              });
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                store.dispatch({
                  type: "SHOW_ALERT",
                  payload: {
                    variant: "danger"
                  },
                });
              } else {
                let err = {
                  componentName: 'updateAppointmentInventoryBagPacked',
                  statusCode: error.response.status,
                  data: error.response.data,
                };
                store.dispatch(setError(err));
              }
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            });
          break;
          case "UPDATE_APPOINTMENT_INVENTORY_QTY":
            endpoint =
              microServices.base + "/v1/appointment/inventory/qty/" + action?.payload?.appointment_id;
            APIservice.put(endpoint, action.payload.formData)
              .then((response) => {
                store.dispatch({
                  type: "SHOW_ALERT",
                  payload: {
                    variant: "success",
                    componentName: 'updateAppointmentInventoryQty',
                  },
                });
              })
              .catch((error) => {
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "danger"
                    },
                  });
                } else {
                  let err = {
                    componentName: 'updateAppointmentInventoryQty',
                    statusCode: error.response.status,
                    data: error.response.data,
                  };
                  store.dispatch(setError(err));
                }
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              });
            break;
      
            case "FETCH_MASTER_INVENTORY_TEMPLATES":
                let masterInventoryEndpoint = microServices.base + '/v1/dropdown/inventory-templates?per_page=0';
                APIservice
                .get(masterInventoryEndpoint)
                .then((response)=>{
                  const templateResponse = response.data.data;
                  const inventoryTemplates = prepareInventoryTemplatesDropdownOptions(templateResponse);
                  store.dispatch({ 
                    type: 'SET_INVENTORY_TEMPLATE_LIST', 
                    payload: inventoryTemplates,
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_INVENTORY_TEMPLATE_LIST', 
                      payload: [],
                    });
                  } 
                })
            break;
            case "UPDATE_APPOINTMENT_INVENTORY_RESUPPLY_COUNT":
              endpoint = `${microServices.base}/v2/appointment/inventory-resupply-count/web/${action.payload.properties_id}/${action.payload.inventory_template_item_id}`;
              APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      componentName: "updateAppointmentInventoryResupplyCount",
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                      },
                    });
                  } else {
                    const err = {
                      componentName: "updateAppointmentInventoryResupplyCount",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
            break;

            default:
            break;
    }
    return result;
};

export default InventoryMiddleware;