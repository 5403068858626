const initState = {
  configuration: [],
  showInvalidPaymentDetails   : false,
};

const Billing = (state = initState, action) => {

  switch (action.type) {

    case 'SET_BILLING_CONFIG':
      return {
        ...state,
        configuration: action.payload
      };
    case 'CLEAR_BILLING_CONFIG':
      return {
        ...state,
        configuration: null
      }
    case "SET_PAYMENT_TYPE": {
      return {
        ...state,
        paymentType: action.payload,
      }
    }

    case "CLEAR_PAYMENT_TYPE": {
      return {
        ...state,
        paymentType: [],
      }
    }

    case 'SET_PAYMENT_MODE':
      return {
        ...state,
        paymentMode: action.payload
      };

    case "CLEAR_PAYMENT_MODE": {
      return {
        ...state,
        paymentMode: [],
      }
    }
    case 'SET_PAYMENT_MODE_LIST':
      return {
        ...state,
        paymentModeList: action.payload
      };

    case "SET_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethod: action.payload,
      }


    case "CLEAR_PAYMENT_METHOD": {
      return {
        ...state,
        paymentMethod: [],
      }
    }
    case "SET_COMPANY_WALLET":
      return {
        ...state,
        companyWallet: action.payload.data,
      }


    case "CLEAR_COMPANY_WALLET": {
      return {
        ...state,
        companyWallet: [],
      }
    }

    case "SET_COMPANY_TILLED":
      return {
        ...state,
        companyTilled: action.payload.data,
      }


    case "CLEAR_COMPANY_TILLED": {
      return {
        ...state,
        companyTilled: [],
      }
    }

    case "SET_TILLED_PRODUCT_CODES":
      return {
        ...state,
        tilledProductCodes: action.payload.data,
      }


    case "CLEAR_TILLED_PRODUCT_CODES": {
      return {
        ...state,
        tilledProductCodes: [],
      }
    }

      
    case 'SET_COMPANY_STRIP':
      return {
        ...state,
        getStrip: action.payload
      };

    case 'CLEAR_COMPANY_STRIP':
      return {
        ...state,
        getStrip: null
      };

    case 'SET_TRANSACTION_DETAILS':
    return {
      ...state,
      getTransactionDetails: action.payload.data
    };

    case 'CLEAR_TRANSACTION_DETAILS':
    return {
      ...state,
      getTransactionDetails: null
    };

    case 'SET_PAYMENT_GATEWAYS':
      return {
        ...state,
        paymentGtwAndMethods: action.payload.data
      };
  
    case 'CLEAR_PAYMENT_GATEWAYS':
      return {
        ...state,
        paymentGtwAndMethods: null
      };
    
    case "SET_AUTO_PAY_REQUEST":
        return {
          ...state,
          autoPayRquest: action.payload.data,
      };
  
    case "CLEAR_AUTO_PAY_REQUEST":
        return {
          ...state,
          autoPayRquest: []
      };

    case "SET_APPT_WORKORDER_LIST": {
      return {
        ...state,
        apptWorkorderList: action.payload,
      }
    }

    case "CLEAR_APPT_WORKORDER_LIST":
      return {
        ...state,
        apptWorkorderList: []
    };

    case "CLEAR_AUTO_PAY_DATA":
      return {
        ...state,
        autoPayData: []
    };

  case "SET_AUTO_PAY_DATA": 
    return {
      ...state,
      autoPayData: action.payload,
    }
    
    case 'SET_QUICK_BOOK':
      return {
        ...state,
        getQuickBook: action.payload
      };

    case 'SET_QB_COMPANYINFO':
        return {
          ...state,
          getQBCompanyInfo: action.payload
    };

    case "SET_QB_ERROR_MESSAGES": 
    return {
      ...state,
      qbErrorsData: action.payload,
    }

    case "SET_QB_PAYROLL_ERRORS": 
    return {
      ...state,
      qbPayrollErrors: action.payload,
    }

    case "CLEAR_QB_PAYROLL_ERRORS": 
    return {
      ...state,
      qbPayrollErrors: [],
    }

    case "Clear_QB_ERROR_MESSAGES": 
    return {
      ...state,
      qbErrorsData: [],
    }

    case "SET_QB_PAYROLL_COA":
      return {
        ...state,
        qbPayrollCoa: action.payload,
      }

    case "CLEAR_QB_PAYROLL_COA":
      return {
        ...state,
        qbPayrollCoa: [],
      }
      case 'SET_COMPANY_CARDPOINTE':
        return {
          ...state,
          cardpointeDetails: action.payload
        };
  
      case 'CLEAR_COMPANY_CARDPOINTE':
        return {
          ...state,
          cardpointeDetails: null
        };
      case 'SET_COMPANY_CARDPOINTE_SIGNATURE':
        return {
          ...state,
          cardpointeSignature: action.payload
        };
      case 'CLEAR_COMPANY_CARDPOINTE_SIGNATURE':
          return {
            ...state,
            cardpointeSignature: null
      }     
    case "SET_AGREEMENT_REQUEST":	
      return {	
        ...state,	
        agreementRequest: action.payload.data,	
    };	
    case "CLEAR_AGREEMENT_REQUEST":	
      return {	
        ...state,	
        agreementRequest: null,	
    };
    case "TOGGLE_INVALID_PAYMENT_DETAILS":
      return {
        ...state,
        showInvalidPaymentDetails: true
      }

   default:
      return state;
  }
};

export default Billing;
