import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { preparePropertyGroup } from "../../utils/utils";
/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store
 * @returns
 */
const AdminMiddleware = (store) => (next) => async (action) => {
    // Our middleware
    const result    = next(action);
    let endpoint  = microServices.base+'/v1/admin';
    let endpointV2 =  microServices.base + '/v2';
    switch (action.type) {
        case "CREATE_ADMIN":
            store.dispatch(isLoader(true));

              APIservice
                .post(endpoint, action.payload)
               .then((response)=>{
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant:'success',
                      message: 'User added successfully!',
                      componentName:'AddAdmin'
                    }
                  });
                  store.dispatch({
                    type: 'ASSIGN_ACCESS_ROLE',
                    payload: {
                      "accessRoles":action.payload.accessRoles,
                      "adminId":response.data.data.user_id }
                    });

                })
                .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant:'danger',
                        message: 'Unable to save user information!'
                      }
                    });
                  } else {
                    let err = {componentName:'AddAdmin',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                })
            break;

      case "FETCH_ADMIN":
        store.dispatch(isLoader(true));
        APIservice
          .get(endpoint + '/' + action.payload.user_id)
          .then((response) => {
            if (response && response.data) {
              store.dispatch({
                type: 'SET_DATA_FOR_EDIT',
                payload: {
                  data: response.data.data
                }
              });
            }
          })
          .catch((error) => {
            let err = { componentName: 'Admin', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;

        case "UPDATE_ADMIN":
          store.dispatch(isLoader(true));
          console.log("herdde");
          APIservice
            .put(endpoint + '/' + action.payload.id, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  message: 'User updated successfully!',
                  componentName: 'EditAdmin'
                }
              });
              store.dispatch({
                type: 'UPDATE_ACCESS_ROLE',
                payload: {
                        "accessRoles":action.payload.formData.accessRoles,
                        "adminId":action.payload.id }
                });

            })
            .catch((error) => {
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: 'Unable to save user information!',
                  }
                });
              } else {
                let err = { componentName: 'EditAdmin', statusCode: error.response.status, data: error.response.data };
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;

          case "DROPDOWN_ADMIN_LIST":
            APIservice
                .get(endpoint+'?filter[status_id]=1')
                .then((response)=>{
                  if(response && response.data){
                    let accountManagerList = [];
                    response.data.data.forEach((adminlist) => {
                      accountManagerList.push({
                        value: adminlist.user_id,
                        label: adminlist.first_name + ' ' + adminlist.last_name,
                        email: adminlist.email
                      });
                    });

                    store.dispatch({
                      type: 'SET_ADMIN_DROPDOWN_LIST',
                      payload: {
                        data:accountManagerList
                      }
                    });
                  }
                })
                .catch((error)=>{

                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant:'danger',
                        message: 'Unable to load account manager',
                      }
                    });

                })

            break;

            case "ASSIGN_PM_CL":
              endpoint  = microServices.base+'/v1/company/'+action.payload.userType+'/assign';
              const dispalyName = (action.payload.userType==='pm')? 'Property Manager':'Cleaner'
              store.dispatch(isLoader(true));
                APIservice
                  .post(endpoint, action.payload.formData)
                  .then((response)=>{
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant:'success',
                        message: dispalyName + ' Assigned successfully!',
                        componentName:'EditBilling'
                      }
                    });
                  })
                  .catch((error)=>{
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant:'danger',
                          message: 'Unable to Assign Property Manager!',
                        }
                      });
                    } else {
                      let err = {componentName:'EditBilling',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
              break;


          case "ACTIVE_INACTIVE_ADMIN":
            endpoint = microServices.base+'/v1';
            store.dispatch(isLoader(true));
            APIservice
              .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
              .then((response)=>{
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant:'success',
                    message: `Subadmin ${(action.payload.apiName === 'inactivate')?'deactivated':'activated'} successfully!`,
                    componentName:'ActiveInactiveAdmin'
                  }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant:'danger',
                      message: `Unable to ${action.payload.apiName} admin information!`
                    }
                  });
                } else {
                    let err = {componentName:'ActiveInactiveAdmin',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;

            case "FETCH_ADDITIONAL_FEATURES":
              store.dispatch(isLoader(true));
              APIservice
                .get(microServices.base + '/v1/company/setting/additional-features/' + action.payload.company_id)
                .then((response) => {
                  if (response && response.data) {
                    store.dispatch({
                      type: 'SET_ADDITIONAL_FEATURES',
                      payload: {
                        data: response.data.data
                      }
                    });
                  }
                })
                .catch((error) => {
                  let err = { componentName: 'EditAdditionalFeatures', statusCode: error.response.status, data: error.response.data };
                  store.dispatch(setError(err));
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                })
            break;

            case "ASSIGN_AC_MANAGER":
                endpoint  = microServices.base+'/v1/company/account-manager/assign';
                store.dispatch(isLoader(true));
                APIservice
                .post(endpoint, action.payload.formData)
                .then((response)=>{
                    store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                        variant:'success',
                        message: 'Account Manager Assigned successfully!',
                        componentName:'AssignAccountManager'
                    }
                    });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                        variant:'danger',
                        message: 'Unable to assign account manager!'
                        }
                    });
                    } else {
                    let err = {componentName:'AssignAccountManager',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                    }
                }).finally(()=>{
                    store.dispatch(isLoader(false));
                  })
            break;


            case "ASSIGN_ADDITIONAL_FEATURES":
              store.dispatch(isLoader(true));
              APIservice
                .put(microServices.base + '/v1/company/setting/additional-features/' + action.payload.company_id, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'success',
                      message: 'Additional Features updated successfully!',
                      componentName: 'EditAdmin',
                    }
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {

                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'danger',
                        message: 'Unable to update Additional Features!',
                      }
                    });
                  } else {
                    let err = { componentName: 'EditAdmin', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
            case "ASSIGN_ACCESS_ROLE":

              endpoint  = microServices.base+'/v1/company/staff/access-role/'+action.payload.adminId;
              store.dispatch(isLoader(true));

                APIservice
                  .post(endpoint, action.payload)
                  .then((response)=>{
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant:'success',

                        componentName:'AccescRight'
                      }
                    });
                  })
                  .catch((error)=>{
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant:'danger',
                          message: 'Unable to Assign Access role!',
                        }
                      });
                    } else {
                      let err = {componentName:'AccessRight',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
              break;

              case "UPDATE_ACCESS_ROLE":

                endpoint  = microServices.base+'/v1/company/staff/access-role/'+action.payload.adminId;
                store.dispatch(isLoader(true));

                  APIservice
                    .put(endpoint, action.payload)
                    .then((response)=>{
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant:'success',

                          componentName:'AccescRight'
                        }
                      });
                    })
                    .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant:'danger',
                            message: 'Unable to Assign Access role!',
                          }
                        });
                      } else {
                        let err = {componentName:'AccessRight',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;

                case "ACTIVATE_DEACTIVATE_CLEANER_MANAGER":

                  let endpt  = microServices.base+'/v1/admin/' + action.payload.api;
                  store.dispatch(isLoader(true));
                    APIservice
                      .post(endpt, action.payload.formData)
                      .then((response)=>{
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant:'success',
                            message : action.payload.successMessage,
                            componentName:'ActivateDeactivateCleanerManager'
                          }
                        });
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant:'danger',
                              message: action.payload.failMessage,
                            }
                          });
                        } else {
                          let err = {componentName:'ActivateDeactivateCleanerManager',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                  break;
                  case "FETCH_PM_CL":
                    let endpointCLPM = microServices.base + '/v1/company/'+action.payload.userType+'?per_page='+action.payload.per_page;
                    let filterUrl = '';

                    if (action.payload.filter) {
                      filterUrl = prepareFilterUrl(action.payload.filter);
                      endpointCLPM = endpointCLPM+'&'+filterUrl;
                    }

                    APIservice
                      .get(endpointCLPM)
                      .then((response) => {
                        let CLPMList;
                        CLPMList =  prepareGroupCLPMs(response,action.payload.userType);
                        store.dispatch({
                          type: 'SET_PM_CL',
                          payload: {
                            data: CLPMList
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;
                    case "RC_INVOICE_FUNCTIONALITY":
                    store.dispatch(isLoader(true));
                     let api = microServices.base +'/v1/admin/invoice'+action.payload.url;
                      APIservice
                        .post(api, action.payload.data)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.sucessMessage,
                              componentName: action.payload.componentName,
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.errorMessage,
                              },
                            });
                          } else {
                            let err = {
                              componentName: action.payload.componentName,
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                      case "RC_INVOICES_PDF":
                        store.dispatch(isLoader(true));
                        let apiEndpoint  = action?.payload?.selectedLanguage ? microServices.base+'/v1/'+action?.payload?.selectedLanguage+'/admin/invoice/downloadPdf' : microServices.base+'/v1/admin/invoice/downloadPdf';
                        APIservice.
                        getBlobDataForMultipleRecord(apiEndpoint, action.payload)
                        .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                             const link = document.createElement('a');
                             link.href = blobUrl;
                             link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                             document.body.appendChild(link);
                             link.click();
                             link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                          let err = {componentName:'InvoicesPDF',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;
                      case "SAVE_RCINVOICE_TRANSACTION_DETAILS":
                        endpoint = microServices.base + '/v1/admin/invoice/transactionDetail';
                        store.dispatch(isLoader(true));
                        APIservice
                            .post(endpoint, action.payload)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: `Transaction created Successfully `,
                                            componentName: 'createInvoiceTransaction'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                                let err = { componentName: 'createInvoiceTransaction', statusCode: error.response.status, data: error.response.data};
                                store.dispatch(setError(err));
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })
                        break;
                        case "LOCK_UNLOCK_COMPANY":
                        store.dispatch(isLoader(true));
                        APIservice
                            .put(action.payload.api)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload.successMessage,
                                            componentName: 'LockUnlockCompany'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                              if (error.response.status !== 422) {
                                store.dispatch({
                                  type: "SHOW_ALERT",
                                  payload: {
                                    variant: "danger",
                                    message: action.payload.errorMessage,
                                  },
                                });
                              } else {
                                let err = {
                                  componentName: 'LockUnlockCompany',
                                  statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })
                        break;
                      case "ENABLE_DISABLE_VACASA":
                        let vacasaEndpoint = action.payload.api;
                        store.dispatch(isLoader(true));
                        APIservice
                            .put(vacasaEndpoint)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload.successMessage,
                                            componentName: action.payload?.componentName ? action.payload.componentName : 'EnableDisableVacasa'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                              if (error.response.status !== 422) {
                                store.dispatch({
                                  type: "SHOW_ALERT",
                                  payload: {
                                    variant: "danger",
                                    message: action.payload.errorMessage,
                                  },
                                });
                              } else {
                                let err = {
                                  componentName: 'EnableDisableVacasa',
                                  statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })
                        break;

                        case "ENABLE_DISABLE_EVOLVE":
                        let evolveEndpoint  = microServices.base+'/v1/company/cleaner/evolve/'+action.payload.company_id;
                        store.dispatch(isLoader(true));
                        APIservice
                            .put(evolveEndpoint,action.payload.formData)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload.successMessage,
                                            componentName: 'EnableDisableEvolve'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                              if (error.response.status !== 422) {
                                store.dispatch({
                                  type: "SHOW_ALERT",
                                  payload: {
                                    variant: "danger",
                                    message: action.payload.errorMessage,
                                  },
                                });
                              } else {
                                let err = {
                                  componentName: 'EnableDisableEvolve',
                                  statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })
                        break;
                        case "REMOVE_LATE_FEES":
                          store.dispatch(isLoader(true));
                          APIservice
                              .put(action.payload.api)
                              .then((response) => {
                                  if (response) {
                                      store.dispatch({
                                          type: 'SHOW_ALERT',
                                          payload: {
                                              variant: 'success',
                                              message: action.payload.successMessage,
                                              componentName: 'removeLateFees'
                                          }
                                      });
                                  }
                              })
                              .catch((error) => {
                                if (error.response.status !== 422) {
                                  store.dispatch({
                                    type: "SHOW_ALERT",
                                    payload: {
                                      variant: "danger",
                                      message: action.payload.errorMessage,
                                    },
                                  });
                                } else {
                                  let err = {
                                    componentName: 'removeLateFees',
                                    statusCode: error.response.status,
                                    data: error.response.data
                                  };
                                  store.dispatch(setError(err));
                                }
                              })
                              .finally(() => {
                                  store.dispatch(isLoader(false));
                              })
                          break;
                          case "GET_EMAIL_TEMPLATE":
                          let endPoint  = microServices.notification+'/v1/emailtemplates/'+action.payload.id;
                          store.dispatch(isLoader(true));
                          APIservice
                            .get(endPoint)
                            .then((response) => {
                              if (response && response.data) {
                                store.dispatch({
                                  type: 'SET_EMAIL_TEMPLATE',
                                  payload: {
                                    data: response.data.data
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                              let err = { componentName: 'EditEmailTemplate', statusCode: error.response.status, data: error.response.data };
                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                          break;

                          case "UPDATE_EMAIL_TEMPLATE":
                            let apiendPoint  = microServices.notification+'/v1/emailtemplates/'+action.payload.id;
                            store.dispatch(isLoader(true));
                            APIservice
                              .post(apiendPoint, action.payload.formData)
                              .then((response) => {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: 'Email template updated successfully!',
                                    componentName: 'UpdateEmailTemplate'
                                  }
                                });

                              })
                              .catch((error) => {
                                if (error.response.status !== 422) {
                                  store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                      variant: 'danger',
                                      message: 'Unable to update email template!',
                                    }
                                  });
                                } else {
                                  let err = { componentName: 'UpdateEmailTemplate', statusCode: error.response.status, data: error.response.data };
                                  store.dispatch(setError(err));
                                }
                              })
                              .finally(()=>{
                                store.dispatch(isLoader(false));
                              })
                            break;
                            case "GET_NOTIFICATION_TEMPLATE":
                            let apiEndPoint  = microServices.notification+'/v1/emailnotifications/'+action.payload.id;
                            store.dispatch(isLoader(true));
                            APIservice
                              .get(apiEndPoint)
                              .then((response) => {
                                if (response && response.data) {
                                  store.dispatch({
                                    type: 'SET_NOTIFICATION_TEMPLATE',
                                    payload: {
                                      data: response.data.data
                                    }
                                  });
                                }
                              })
                              .catch((error) => {
                                let err = { componentName: 'EditNotificationTemplate', statusCode: error.response.status, data: error.response.data };
                                store.dispatch(setError(err));
                              })
                              .finally(() => {
                                store.dispatch(isLoader(false));
                              })
                            break;

                            case "UPDATE_NOTIFICATION_TEMPLATE":
                              let apis  = microServices.notification+'/v1/emailnotifications/'+action.payload.id;
                              store.dispatch(isLoader(true));
                              APIservice
                                .post(apis, action.payload.formData)
                                .then((response) => {
                                  store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                      variant: 'success',
                                      message: 'Notification template updated successfully!',
                                      componentName: 'UpdateNotificationTemplate'
                                    }
                                  });

                                })
                                .catch((error) => {
                                  if (error.response.status !== 422) {
                                    store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                        variant: 'danger',
                                        message: 'Unable to update notification template!',
                                      }
                                    });
                                  } else {
                                    let err = { componentName: 'UpdateNotificationTemplate', statusCode: error.response.status, data: error.response.data };
                                    store.dispatch(setError(err));
                                  }
                                })
                                .finally(()=>{
                                  store.dispatch(isLoader(false));
                                })
                              break;

                    case "FETCH_PM":
                    let endpointPM = microServices.base + '/v1/company/'+action.payload.userType+'?per_page='+action.payload.per_page;
                    let filterUrlPM = '';

                    if (action.payload.filter) {
                      filterUrlPM = prepareFilterUrl(action.payload.filter);
                      endpointPM = endpointCLPM+'&'+filterUrlPM;
                    }

                    APIservice
                      .get(endpointPM)
                      .then((response) => {
                        let PMList;
                        PMList =  prepareGroupCLPMs(response,action.payload.userType);
                        store.dispatch({
                          type: 'SET_PM',
                          payload: {
                            data: PMList
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;

                    case "FETCH_CL":
                    let endpointCL = microServices.base + '/v1/company/'+action.payload.userType+'?per_page='+action.payload.per_page;
                    let filterUrlCL = '';

                    if (action.payload.filter) {
                      filterUrlCL = prepareFilterUrl(action.payload.filter);
                      endpointCL = endpointCL+'&'+filterUrlCL;
                    }

                    APIservice
                      .get(endpointCL)
                      .then((response) => {
                        let CLList;
                        CLList =  prepareGroupCLPMs(response,action.payload.userType);
                        store.dispatch({
                          type: 'SET_CL',
                          payload: {
                            data: CLList
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;

                    case "FETCH_PM_CLEANER":
                    let endPointPMCleaner = microServices.base + '/v1/admin/cleaners/'+action.payload.id;
                    let filterUrlPMCleaner = '';

                    APIservice
                      .get(endPointPMCleaner)
                      .then((response) => {
                        let PMCLEANERList;
                        PMCLEANERList =  prepareGroupCLPMs(response,"cleaner");
                        store.dispatch({
                          type: 'SET_PM_CLEANER',
                          payload: {
                            data: PMCLEANERList
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;

                    case "FETCH_CL_PROJECTMANAGER":
                    let endPointCLPM = microServices.base + '/v1/admin/managers/'+action.payload.id;
                    let filterUrlCleanerPM = '';

                    APIservice
                      .get(endPointCLPM)
                      .then((response) => {
                        let CLPMData;
                        CLPMData =  prepareGroupCLPMs(response,"property-manager");
                        store.dispatch({
                          type: 'SET_CL_PROJECTMANAGER',
                          payload: {
                            data: CLPMData
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;
                    case "FETCH_ADMIN_PROPERTIES":
                    let endPointProperty = microServices.base + '/v1/admin/properties?per_page=20';
                    let filterUrlProperty = '';

                    APIservice
                      .get(endPointProperty)
                      .then((response) => {
                        let propertyList;
                        propertyList =  preparePropertyGroup(response);
                        store.dispatch({
                          type: 'SET_ADMIN_PROPERTY',
                          payload: {
                            data: propertyList
                          }
                        });
                      })

                      .catch((error) => {
                        if (error.response?.status !== 422) {
                          store.dispatch({
                            type: 'SET_STAFF_DROPDOWN',
                            payload: [],
                          });
                        }
                      })
                    break;

                    case "UPDATE_PRICE_PRICING":
                      store.dispatch(isLoader(true));
                      let endPointUpdate = microServices.base + '/v1/admin/property/pricing/'+action?.payload?.type;
                      APIservice
                        .put(endPointUpdate, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: 'Property pricing updated successfully!',
                              componentName: 'UpdatePricePricing'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: 'Unable to update information!',
                              }
                            });
                          } else {
                            let err = { componentName: 'UpdatePricePricing', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;

                    case "UPDATE_SYNC_OWNEREZ":
                      store.dispatch(isLoader(true));
                      let ownerrezEndPoint = microServices.integration + '/v1/ownerrezpullappointment';
                      APIservice
                        .post(ownerrezEndPoint, action.payload)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: 'Ownerrez Appointment pull successfully!',
                              componentName: 'UpdateOwnerrezSync'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: 'Unable to pull Ownerrez Appointments!',
                              }
                            });
                          } else {
                            let err = { componentName: 'UpdateOwnerrezSync', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;

                    case "UPDATE_RESET_API":
                      store.dispatch(isLoader(true));
                      let resetApiEndPoint = microServices.integration + '/v1/reset/' + action.payload.company_id;
                      APIservice
                        .post(resetApiEndPoint)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: 'Integration Records Reset Successfully!',
                              componentName: 'UpdateResetApi'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: 'Unable to Reset Api!',
                              }
                            });
                          } else {
                            let err = { componentName: 'UpdateResetApi', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;
                    case "UPDATE_APPOINTMENT_UID":
                      store.dispatch(isLoader(true));
                      let uidEndpoint = action.payload.endpoint;
                      APIservice
                        .put(uidEndpoint)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: response?.data?.message,
                              componentName: 'AdminSearchTool'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: 'Unable to update UID!',
                              }
                            });
                          } else {
                            let err = { componentName: 'AdminSearchTool', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;

                    case "DELETE_INTEGRATED_APPOINTMENTS":
                      store.dispatch(isLoader(true));
                      let deleteIntegrationEndPoint = microServices.integration + '/v1/delete/' + action.payload.company_id;
                      APIservice
                        .delete(deleteIntegrationEndPoint)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: 'Delete Integration Appointment Successfully!',
                              componentName: 'DeleteIntegrationAppointments'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: 'Unable to Delete Integration Appointment!',
                              }
                            });
                          } else {
                            let err = { componentName: 'DeleteIntegrationAppointments', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;
                    case "EXPORT_RC_INVOICES":
                      store.dispatch(isLoader(true));
                      let apiEndpoints  = action.payload.endpoint;
                      APIservice.
                      getBlobDataForMultipleRecord(apiEndpoints, action.payload)
                      .then((response)=>{
                        if(action.payload?.isDownloadReport){
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Invoice"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                        }else{
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: action.payload?.successMessage,
                              componentName: 'InvoicesPDF'
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        let err = {componentName:'InvoicesPDF',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                    break;

                    case "EXPORT_RC_INVOICE_ALL":
                      store.dispatch(isLoader(true));
                      APIservice.
                      getBlobDataWithParams(action.payload.exportApi,"")
                      .then((response)=>{
                        if(action.payload?.isDownloadReport){
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                        }
                        else{
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: action.payload?.successMessage,
                              componentName: 'DownloadRcInvoiceXlsx'
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadRcInvoiceXlsx',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                          store.dispatch(isLoader(false));
                      });
                  break;
                  case "EXPORT_ADMIN_CLEANER_MANAGER":
                      let exportApi = action.payload.exportApi;
                      store.dispatch(isLoader(true));
                      APIservice.
                      getBlobDataWithParams(exportApi,"")
                      .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadAdminCLMNXlsx',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                          store.dispatch(isLoader(false));
                      });
                  break;

                  case "PRINT_ADMIN_CLEANER_MANAGER":
                  let printApi = action.payload.printApi;
                  store.dispatch(isLoader(true));
                  APIservice.
                  getBlobDataWithParams(printApi,"")
                  .then((response)=>{
                      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                  })
                  .catch((error)=>{
                      if (error.response.status !== 422) {
                          store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                  variant:'danger',
                                  message: 'Unable to Download Report!',
                              }
                          });
                      } else {
                          let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                      }
                  })
                  .finally(()=>{
                      store.dispatch(isLoader(false));
                  });
                  break;
                    case "SET_SLOW_NETWORK":
                      store.dispatch(isLoader(true));
                      let lowNetworkEndpoints  = microServices.base+'/v1/company/setting/slow-network/'+ action.payload.company_id;
                      APIservice.put(lowNetworkEndpoints,action.payload.formData)
                      .then((response)=>{
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            message: 'Slow Network Updated Successfully!',
                            componentName: 'UpdateSlowNetwork'
                          }
                        });
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'danger',
                              message: 'Unable to update low network',
                            }
                          });
                        } else {
                          let err = { componentName: 'UpdateSlowNetwork', statusCode: error.response.status, data: error.response.data };
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                    break;
                    case "DROPDOWN_PM_LIST":
                    let endpointPMList = microServices.base + '/v1/company/'+action.payload.userType+'?per_page='+action.payload.per_page+'&filter[status_id]=1';
                    let filterUrlPMList = '';

                    if (action.payload.filter) {
                      filterUrlPMList = prepareFilterUrl(action.payload.filter);
                      endpointPMList = endpointPMList+'&'+filterUrlPMList;
                    }
                      APIservice
                          .get(endpointPMList)
                          .then((response)=>{
                            if(response && response.data){
                              let managerList = [];

                              response.data.data.forEach((pmlist) => {
                                let emailAddress = pmlist.owner.email ? "("+pmlist.owner.email+")" : "";
                                managerList.push({
                                  value: pmlist.id,
                                  label: pmlist.company_name+emailAddress,
                                  email: pmlist.owner.email,
                                  user_id: pmlist.owner.user_id
                                });
                              });

                              store.dispatch({
                                type: 'SET_MANAGER_DROPDOWN_LIST',
                                payload: {
                                  data:managerList
                                }
                              });
                            }
                          })
                          .catch((error)=>{

                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant:'danger',
                                  message: 'Unable to load manager',
                                }
                              });

                          })

                      break;
                      case "DROPDOWN_CL_LIST":
                        let endpointCLList = microServices.base + '/v1/company/'+action.payload.userType+'?per_page='+action.payload.per_page+'&filter[status_id]=1';
                        let filterUrlCLList = '';

                        if (action.payload.filter) {
                          filterUrlCLList = prepareFilterUrl(action.payload.filter);
                          endpointCLList = endpointCLList+'&'+filterUrlCLList;
                        }
                      APIservice
                          .get(endpointCLList)
                          .then((response)=>{
                            if(response && response.data){
                              let cleanerList = [];
                              response.data.data.forEach((cllist) => {
                                let emailAddress = cllist.owner.email ? "("+cllist.owner.email+")" : "";
                                cleanerList.push({
                                  value: cllist.id,
                                  label: cllist.company_name+emailAddress,
                                  email: cllist.owner.email,
                                  user_id: cllist.owner.user_id
                                });
                              });

                              store.dispatch({
                                type: 'SET_CLEANER_DROPDOWN_LIST',
                                payload: {
                                  data:cleanerList
                                }
                              });
                            }
                          })
                          .catch((error)=>{

                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant:'danger',
                                  message: 'Unable to load cleaner',
                                }
                              });

                          })

                      break;
                      case "CREATE_BROADCAST_MESSAGE":
                        store.dispatch(isLoader(true));

                          APIservice
                            .post(endpoint+ '/broadcast-messages', action.payload)
                           .then((response)=>{
                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant:'success',
                                  message: 'Broadcast Message added successfully!',
                                  componentName:'CreateBroadcastMessage'
                                }
                              });
                            })
                            .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant:'danger',
                                    message: 'Unable to save Broadcast Message!'
                                  }
                                });
                              } else {
                                let err = {componentName:'CreateBroadcastMessage',statusCode: error.response.status,data: error.response.data};
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                        break;
                        case "DELETE_MESSAGE_BROADCAST":
                         store.dispatch(isLoader(true));
                        let deleteMessageApi = endpoint + '/'+action.payload.url+'/' + action.payload.id;
                      APIservice
                        .delete(deleteMessageApi)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.sucessMessage,
                              componentName: action.payload.componentName,
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.errorMessage,
                              },
                            });
                          } else {
                            let err = {
                              componentName: action.payload.componentName,
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;

                      case "FETCH_BROADCAST_MESSAGE_USER_LIST": {
                        store.dispatch(isLoader(true));
                        APIservice.get(action.payload.url)
                          .then((response) => {
                            if (response && response?.data) {
                              let usersData;
                              response?.data?.data.map((list) => {
                                  if(list?.users?.length) {
                                    usersData = list.users;
                                  }
                            })
                              store.dispatch({
                                type: "SET_BROADCAST_MESSAGE_USER_LIST",
                                payload: {
                                  data: usersData,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "BroadcastMessageUserList",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        break;
                      }

                      case "UPDATE_SYNC_BAREFOOT":
                      store.dispatch(isLoader(true));
                      let barefootEndPoint = microServices.integration + '/v1/barefootpullappointment';
                      APIservice
                        .post(barefootEndPoint, action.payload)
                        .then((response) => {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: action.componentMessage,
                              componentName: 'UpdateBarefootSync'
                            }
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'danger',
                                message: action.failedMessage,
                              }
                            });
                          } else {
                            let err = { componentName: 'UpdateBarefootSync', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;
                    case "ACCOUNTMN_ADMIN_LIST":
                      APIservice
                          .get(endpoint + '/accountManagers')
                          .then((response)=>{
                            if(response && response.data){
                              let accountManagerAdmins = [];
                              response.data.forEach((adminlist) => {
                                accountManagerAdmins.push({
                                  value: adminlist?.user_id,
                                  label: adminlist?.name,
                                  email: adminlist?.email
                                });
                              });
                              store.dispatch({
                                type: 'SET_ACCOUNTMN_ADMIN_LIST',
                                payload: {
                                  data:accountManagerAdmins
                                }
                              });
                            }
                          })
                          .catch((error)=>{
                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant:'danger',
                                  message: 'Unable to load account manager',
                                }
                              });
                          })

                      break;
                      case "SET_TEST_NORMAL_ACCOUNT":
                        store.dispatch(isLoader(true));
                        APIservice
                            .put(action.payload.api,action?.payload?.formData)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload.successMessage,
                                            componentName: 'TestNormalCompany'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                              if (error.response.status !== 422) {
                                store.dispatch({
                                  type: "SHOW_ALERT",
                                  payload: {
                                    variant: "danger",
                                    message: action.payload.failMessage,
                                    componentName : 'TestNormalCompany'
                                  },
                                });
                              } else {
                                let err = {
                                  componentName: 'TestNormalCompany',
                                  statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })
                        break;

                        case "ADMIN_CHURN_REPORT":
                          endpoint = microServices.base + '/v1/reports/admin-churn-report';
                          store.dispatch(isLoader(true));
                          APIservice.
                          post(endpoint, action.payload.formData)
                          .then((response)=>{
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'success',
                                message: "Churn Report Generated Successfully, You Will Get Email Notification with Attachments.",
                                componentName: 'adminChurnReport'
                              }
                            });
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                                  store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                          variant:'danger',
                                          message: 'Unable to Download Report!',
                                          componentName: 'adminChurnReport',
                                      }
                                  });
                              } else {
                                  let err = {componentName:'adminChurnReport',statusCode: error.response.status,data: error.response.data};
                                  store.dispatch(setError(err));
                              }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                      break;

                      case "MIGRATE_OLD_INVENTORY":
                        store.dispatch(isLoader(true));
                        APIservice
                            .put(action.payload.api,action?.payload?.formData)
                            .then((response) => {
                                if (response) {
                                    store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload.successMessage,
                                            componentName: 'MigrateOldInventory'
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                              if (error.response.status !== 422) {
                                store.dispatch({
                                  type: "SHOW_ALERT",
                                  payload: {
                                    variant: "danger",
                                    message: action.payload.failMessage,
                                    componentName : 'MigrateOldInventory'
                                  },
                                });
                              } else {
                                let err = {
                                  componentName: 'MigrateOldInventory',
                                  statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                              }
                            })
                            .finally(() => {
                                store.dispatch(isLoader(false));
                            })

                        break;

                      case "FETCH_COMPANY_BILLING": {
                        store.dispatch(isLoader(true));
                        let apiEndPoint =endpointV2 + '/company/'+action.payload.companyId+'/billing/feature';
                        APIservice.get(apiEndPoint)
                          .then((response) => {
                            if (response && response?.data) {
                              store.dispatch({
                                type: "SET_COMPANY_BILLING",
                                payload: {
                                  data: response?.data?.data,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "fetchCompanyBilling",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        break;
                      }
                      case "UPDATE_COMPANY_BILLING": {
                        store.dispatch(isLoader(true));
                        let apiEndPoint = endpointV2 + '/company/'+action.payload.companyId+'/billing/feature';
                        APIservice.put(apiEndPoint, action.payload.formData)
                        .then((response)=>{
                        })
                        .catch((error)=>{
                            let err = {componentName:'UpdateCompanyBilling',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                        break;
                      }
                      case "GET_COMPANY_NAME_CHANGE_DETAILS": {
                        store.dispatch(isLoader(true));
                        let apiEndPoint = microServices.base + '/v1/company/'+action.payload.companyId+'/logs';
                        APIservice.get(apiEndPoint)
                        .then((response)=>{
                          const data = response?.data;
                          const firstDateKey = Object.keys(data)[0];
                          const firstItem = data[firstDateKey] ? data[firstDateKey][0] : null;
                          store.dispatch({
                            type: "SET_COMPANY_NAME_CHANGE_DETAILS",
                            payload: {
                              data: firstItem,
                            },
                          });
                        })
                        .catch((error)=>{
                            let err = {componentName:'GetCompanyNameChangeDetails',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                        break;
                      }

        default:
            break;
    }
    return result;
};

const prepareFilterUrl = (filters) => {
  let searchUrl = '';

  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      searchUrl += `filter[${key}]=${value}&`
    }


  }
  return searchUrl;
}

const prepareGroupCLPMs = (response,type) => {
  let ActiveGroupList = [];
  let InactiveGroupList = [];
  let UnverifiedGroupList = [];

  let GroupList = [{
    label: "Active",
    options: ActiveGroupList,
  },
  {
    label: "Inactive",
    options: InactiveGroupList,
  },
  {
    label: "Unverified",
    options: UnverifiedGroupList,
  },
  ];
  let status = 1;

  response.data.data.map((group) => {

    if(group?.owner?.Status?.status_id ) {
      status = (group.owner.Status.status_id === 4) ? group?.owner?.old_status_id : group.owner.Status.status_id;
    } else {
      status = (group?.status?.status_id === 4) ? group.old_status_id : group.status.status_id;
    }
    if (status === 1) {
      ActiveGroupList.push({
            value: group?.id,
            label: group?.company_name+' - '+group?.id
          });

    } else if (status === 2) {
      InactiveGroupList.push({
            value: group?.id,
            label: group?.company_name+' - '+group?.id
          });
    } else if (status === 3) {
      UnverifiedGroupList.push({
        value: group?.id,
        label: group?.company_name+' - '+group?.id
      });
    }
  });
  return GroupList;
}

export default AdminMiddleware;