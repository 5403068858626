import React,{useContext, useEffect, useState} from 'react';
import Loader from '../atoms/Loader';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Style from './App.scss';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from '../../keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollButton from '../atoms/ScrollButton';
import { LanguageProvider } from '../containers/Language';
import { AppRouter } from './AppRouter';
import { Text, LanguageContext } from '../containers/Language';
import Invoicenotification from '../molecules/InvoiceNotification';
import { browserName, browserVersion, isSafari} from "react-device-detect";
import { fetchLeftSidebarCount } from '../../redux/company/action';
import OneSignal from 'react-onesignal';
import { getInventorySmartCredential } from "../../redux/inventorySmart/action";
import { getCompanyWallet, getQuickBook } from '../../redux/Billing/action';
import { getCallbackInspectionCount } from '../../redux/inspection/action';
import { isLoader, updateGlobalFilter } from '../../redux/global/action';
import { logout } from '../../redux/authentication/action';
import moment from 'moment';
import { FreeTrialFeature } from '../../utils/constUtil';
import { QuickbooksAdditionalFeatureId } from '../../utils/additionalFeatureUtil';

let initialValue = 1;

function App(props) {

  const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState(window.location.pathname); 

  useEffect(() => {
    const updatePath = () => setCurrentPath(window.location.pathname);

    const overrideHistoryMethod = (method) => {
      const original = window.history[method];
      window.history[method] = function (...args) {
        original.apply(window.history, args);
        updatePath();
      };
    };

    overrideHistoryMethod('pushState');
    overrideHistoryMethod('replaceState');

    window.addEventListener('popstate', updatePath); 

    return () => {
      window.removeEventListener('popstate', updatePath); 
    };
  }, []);
  const isCleanerDashboard = currentPath === '/cleaner-dashboard';
  const isManagerDashboard = currentPath === '/manager-dashboard';

  const auth = useSelector(state => state.authentication) || '';
  const impersonateData = useSelector(state => state.user.imperData) || '';
  const [initialRender,setInitialRender] = useState(true);
  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  const isProxy = useSelector((state) => state.authentication?.isProxy) || false;
  
  const alert = useSelector((state) => state.global.alert);

  const dnbRemainingDays = loggedInUser?.companyData?.company_subscription?.no_of_remaining_days;
  const hasDnb = loggedInUser?.companyData?.company_subscription?.has_dnb;
  const profileRole = loggedInUser?.role;
  const staffProfileRole = loggedInUser?.companyData?.name;
  const isCleaner = (profileRole === "CL" || profileRole === "ST" && staffProfileRole ==='Cleaner');
  const isManager = profileRole === 'MN' || (profileRole === "ST" && staffProfileRole ==='Manager');
  const freeTrialFeatureLaunchDate = loggedInUser?.companyData?.free_trial_feature_launch_date;
  const isBillingEligible = loggedInUser?.is_billing_eligible;
  const isFreeTrialPopup = loggedInUser?.companyData?.is_free_trial_popup;
  const userData = useSelector((state) => state.user?.user) || '';
  const createdAt = userData?.created_at;
  const dnbAlertDismissed = loggedInUser?.dnb_alert_dismissed;
  const dnbStartDate = loggedInUser?.companyData?.company_subscription?.from_date;
  const dnbFirstThreeDays = moment(dnbStartDate).add(FreeTrialFeature.DAYS_THRESHOLD_THREE_DAYS, 'days');
  const isInLastSevenDays = dnbRemainingDays < FreeTrialFeature.DAYS_THRESHOLD_SEVEN_DAYS;
  const noOfDnbEnabled = loggedInUser?.no_of_dnb_enabled;
  const showFreeTrialMessage = (dnbAlertDismissed === FreeTrialFeature.DAYS_THRESHOLD_SEVEN_DAYS && !isInLastSevenDays) || (moment().isAfter(dnbFirstThreeDays) && !isInLastSevenDays) || noOfDnbEnabled > 1 ;
  const isWalletConfigured = loggedInUser?.companyData?.is_wallet_configured || loggedInUser?.companyData?.is_tilled_wallet_enabled;
  
  const isCleanerWalletPage = window.location.pathname === '/cleaner-wallet';
  const isManagerWalletPage = window.location.pathname === '/manager-wallet';

  const currentURL = window.location.href;
  const { localised } = useContext(LanguageContext);
  
  const tokenLogger = (token) => {
    
    if (token.token && (impersonateData == '' || impersonateData == undefined)) {
      dispatch({
        type: "LOGIN",
        payload: {
          authenticated: true,
          keycloak: keycloak,
          expireTime: keycloak.tokenParsed.exp,
        }
      });

      setTimeout(() => {
        if(initialValue) {
          dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
          initialValue = 0;
        }
      }, 150);
    } 
  };

  useEffect(() => {
    if(currentURL.includes('impersonate') && (impersonateData=='' || impersonateData==undefined)) {
      const params = new URLSearchParams(window.location.search);
      let id = atob(params.get('id'));
      let key = atob(params.get('data'));

      dispatch({type: "SAVE_PROFILE", payload: null});
      dispatch({type: "SET_IS_PROXY", payload: true});
      dispatch({
        type: "LOGIN",
        payload: {
          authenticated: true,
          keycloak: keycloak
        }
      });
      dispatch({type:'USER_IMPERSONATE', payload:{id:id,sameTab : 1,key: key}});
      params.delete('data');
      params.delete('id');
      dispatch(updateGlobalFilter(null));  
    }
  }, []);

  useEffect(() => {
    if (impersonateData && Object.keys(impersonateData).length > 0) {
      dispatch({ type: 'MODIFY_AUTHENTICATION', payload: {...auth.keycloak, authenticated:true, token:impersonateData.access_token, refreshToken:impersonateData.refresh_token}});
      setTimeout(() => {
        dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
        dispatch({ type: 'FETCH_RC_INVOICE_NOTIFICATION' });
        dispatch({ type: 'CLEAR_USER_IMPERSONATE_DATA' });
      }, 150);
    }
  }, [impersonateData]);

  useEffect(() => {
    if(!keycloak.authenticated) {
      return;
    }

    keycloak.onInitError = () => {
      console.log('inside onInitError');
    }

    keycloak.onAuthError = () => {
      console.log("error in token api");
    };

    keycloak.onAuthRefreshError = () => {
      console.log("inside onAuthRefreshError");
    };

    keycloak.onAuthLogout = () => {
      console.log("inside onAuthLogout");
      // dispatch(logout());
    }

    keycloak.onTokenExpired = () => {
        keycloak.updateToken(50).then((resp) => {
          console.log("Token refreshed");
        }).catch((error) => {
          console.log("token error");
          dispatch(logout());
        });
    };

}, [keycloak.authenticated]);

useEffect(() => {
  keycloak?.isLoadingCheck ? dispatch(isLoader(true)) : dispatch(isLoader(false));;
}, [keycloak?.isLoadingCheck]);

useEffect(()=>{
  if(keycloak && initialRender){

    if(loggedInUser.role === "CL" || loggedInUser.role === 'MN' || loggedInUser.role === 'ST')
    {
      let quickbookAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === QuickbooksAdditionalFeatureId);

      if(!isProxy) {
        dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
      }
      dispatch(fetchLeftSidebarCount());
      dispatch(getInventorySmartCredential());

      {quickbookAdditionalFeature &&
        dispatch(getQuickBook({ reqtype: 'companyInfo' })); 
      }
      
      if (loggedInUser.role === "CL" || (loggedInUser.role === 'ST' && loggedInUser?.companyData?.name === 'Cleaner')) {
        dispatch(getCallbackInspectionCount())
      }
      setInitialRender(false);
    }
  }
 
 },[initialRender]);

 useEffect(() => {
   if (loggedInUser && initialValue) {
     OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APPTID}).then(() => {
       OneSignal.showSlidedownPrompt().then(() => {
         console.log("control here...");
       });

       OneSignal.setExternalUserId(String(loggedInUser.user_id));

     });
     //dispatch(getCompanyWallet());
    dispatch({ type: 'FETCH_RC_INVOICE_NOTIFICATION' });
   }
 },[loggedInUser]);

 useEffect(() => {
  if (alert && alert !== null) {
    if (alert.variant === "danger" && alert.componentName=="LoginError") {
      dispatch(isLoader(true));
      toast.error(alert.message);
      setTimeout(() => {
        dispatch(logout());
      }, 1000);
    } 
  }
}, [alert]);

const isNotEmailOtpMode = () => {
  return (localStorage.getItem('invoice_token')) ? false :true;
}
  return (

    <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger} 
      LoadingComponent={<Loader isLoading={true}/>} >
      <LanguageProvider>

        <div className={ isSafari ? 'main-container is-safari' : 'main-container'}>
        <Loader />
        {(isBillingEligible && hasDnb && dnbRemainingDays && moment(createdAt).isBefore(freeTrialFeatureLaunchDate) && (isCleaner || isManager)) ? (
          <div className='d-flex justify-content-end'>
            <a className={`mb-0 no-underline-link ${isWalletConfigured || isCleanerWalletPage || isManagerWalletPage ? 'me-5' : 'me-1'}`}>
              {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining}
            </a>
            {!isWalletConfigured && !isCleanerWalletPage && !isManagerWalletPage && (dnbRemainingDays <= FreeTrialFeature.DAYS_THRESHOLD_SEVEN_DAYS) && (
            <a href={isCleaner ? '/cleaner-wallet' : isManager ? '/manager-wallet' : '#'} className="mb-0 me-5">
              , {localised.autopayRestricatedText2}
            </a>
            )}
          </div>
        ): (
          null
        )}
        {(isBillingEligible && isFreeTrialPopup && dnbRemainingDays && !isCleanerDashboard && !isManagerDashboard && (isCleaner || isManager)) ? (
          <>
            {(dnbRemainingDays <= FreeTrialFeature.DAYS_THRESHOLD_SEVEN_DAYS &&
              dnbRemainingDays >= FreeTrialFeature.DAYS_THRESHOLD_ONE_DAY) ? (
              <div className='d-flex justify-content-end'>
                <a className={`mb-0 no-underline-link ${isWalletConfigured ? 'me-5' : 'me-1'}`}>
                  {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining}
                </a>
                {!isWalletConfigured && !isCleanerWalletPage && !isManagerWalletPage && (
                <a href={isCleaner ? '/cleaner-wallet' : isManager ? '/manager-wallet' : '#'} className="mb-0 me-5">
                  , {localised.autopayRestricatedText2}
                </a>
                )}
              </div>
            ) : (
              <div className='d-flex justify-content-end'>
                <a className="mb-0 me-5 no-underline-link">
                  {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining}
                </a>
              </div>
            )}
          </>
        ):(
          null
        )}
        {(isBillingEligible && isFreeTrialPopup && dnbRemainingDays && (isCleanerDashboard || isManagerDashboard) && (isCleaner || isManager)) ? (
          <>
            {showFreeTrialMessage && !isWalletConfigured &&(
              <div className='d-flex justify-content-end'>
                <a className="mb-0 me-1 no-underline-link">
                  {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining}
                </a>
              </div>
            )}

            {dnbRemainingDays <= FreeTrialFeature.DAYS_THRESHOLD_SEVEN_DAYS &&
            dnbRemainingDays > FreeTrialFeature.DAYS_THRESHOLD_THREE_DAYS &&
            dnbAlertDismissed === FreeTrialFeature.DISMISS_STATUS_SEVEN_DAYS_REMAINING && !isWalletConfigured && (
              <div className='d-flex justify-content-end'>
                <a className="mb-0 me-1 no-underline-link">
                  {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining},
                </a>
                <a href={isCleaner ? '/cleaner-wallet' : isManager ? '/manager-wallet' : '#'} className="mb-0 me-5">
                  {localised.autopayRestricatedText2}
                </a>
              </div>
            )}

            {dnbRemainingDays <= FreeTrialFeature.DAYS_THRESHOLD_THREE_DAYS &&
            dnbRemainingDays > FreeTrialFeature.DAYS_THRESHOLD_ONE_DAY &&
            dnbAlertDismissed === FreeTrialFeature.DISMISS_STATUS_THREE_DAYS_REMAINING && !isWalletConfigured && (
              <div className='d-flex justify-content-end'>
                <a className="mb-0 me-1 no-underline-link ">
                  {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining},
                </a>
                <a href={isCleaner ? '/cleaner-wallet' : isManager ? '/manager-wallet' : '#'} className="mb-0 me-5">
                  {localised.autopayRestricatedText2}
                </a>
              </div>
            )}

            {!(dnbRemainingDays <= FreeTrialFeature.DAYS_THRESHOLD_ONE_DAY && dnbAlertDismissed !== 0) && !isWalletConfigured && null}
          </>
        ):(
          null
        )}{(isBillingEligible && isFreeTrialPopup && dnbRemainingDays && (isCleanerDashboard || isManagerDashboard) && (isCleaner || isManager) && isWalletConfigured) ? (
          <div className='d-flex justify-content-end'>
            <a className="mb-0 me-5 no-underline-link ">
              {localised.freeTrial} {dnbRemainingDays} {localised.daysRemaining}
            </a>
          </div>
        ):(
          null
        )}

          <AppRouter />
        </div>
        <ToastContainer
          enableMultiContainer
          closeButton
          containerId={'B'}
          position="top-right"
          autoClose={false}
          newestOnTop = {false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable = {false}
          theme="colored"
          className='custom-toast-conatiner'
        />
        <ToastContainer enableMultiContainer limit={1} autoClose={1000} theme="colored" />
        { loggedInUser && <ScrollButton />}
        { loggedInUser && isNotEmailOtpMode() &&
        <Invoicenotification/>
        }
        
      
      </LanguageProvider>
    </ReactKeycloakProvider>
  );
}

export default App;