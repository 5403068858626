import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { isEmptyNullUndefined } from "../../utils/utils";

/**
 * DashboardMiddleware can be used to call Dashboard related API services
 * @param {*} store
 * @returns
 */
const DashboardMiddleware = (store) => (next) => async (action) => {
    const result = next(action);
    let endpoint = microServices.base + '/v1/dashboard';
    switch (action.type) {
        case "FETCH_APPOINTMENT_STATS":
            endpoint = endpoint + '/appointment/counts/' + action.payload.date
            if(!isEmptyNullUndefined(action.payload.filter)){
                endpoint = endpoint + action.payload.filter
            }

            APIservice.get(endpoint)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: "SET_DASHBOARD_APPOINTMENT_STATS",
                            payload: response.data.data,

                        });
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "DashboardAppointmentStats",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
            break;
            case "FETCH_PROPERTY_REVENUE_LIST":
                APIservice.get(action.payload.url)
                .then((response) => {
                    if (response && response.data) {
                        let descTemp = [...response.data.data]
                        let key = 'CurrentYearPrice';
                        let sortedData = descTemp.sort(function(a, b) {
                            let x = Number(a[key]); let y = Number(b[key]);
                            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
                        }); 
                        store.dispatch({
                            type: "SET_PROPERTY_REVENUE_LIST",
                            payload: sortedData,
                        });
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "PropertyRevenueList",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
            break;
            case "FETCH_PROPERTY_READINESS_LIST":
                APIservice.get(action.payload.url)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: "SET_PROPERTY_READINESS_LIST",
                            payload: response.data.data,
                        });
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "PropertyReadinessList",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
            break;

            case "STATUS_CENTER_DOWNLOAD_REPORT": {
                let fileName = "";
                let type = "print";
                let ext = ".pdf";
                if(action.payload.fileType == 'excel') {
                    type = "export";
                    ext = ".xlsx";
                }

                if (action.payload.list == 'appointments') {
                    endpoint += `/status-center/appointments/${type}?per_page=0&filter[is_client_service]=false&filter[app_status_id]=2,3,4&filter[aptBetween]=${action.payload.aptBetween}`;
                    fileName = "Appointments Status Summary";
                } else if (action.payload.list == 'serviceReq') {
                    endpoint += `/status-center/appointments/${type}?per_page=0&filter[is_client_service]=true&filter[app_status_id]=2,3,4&filter[aptBetween]=${action.payload.aptBetween}`;
                    fileName = "Service Requests Status Summary";
                } else if (action.payload.list == 'workorders') {
                    endpoint += `/status-center/workorders/${type}?per_page=0&filter[app_status_id]=1,2,3&filter[orderBetween]=${action.payload.aptBetween}`;
                    fileName = "Workorders Status Summary";
                }
                if(action.payload.globalRegionArea) endpoint += action.payload.globalRegionArea;
                APIservice.getBlobDataWithParams(endpoint)
                    .then((response) => {
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', fileName + ext);
                        document.body.appendChild(link);
                        link.click(); 
                        link.parentNode.removeChild(link);
                    })
                    .catch((error) => {
                        if (error.response.status !== 422) {
                            store.dispatch({ 
                                type: 'SHOW_ALERT', 
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to download report!',
                                    componentName: 'StatusCenterDownloadReport',
                                }
                            });
                        } else {
                            let err = {componentName: 'StatusCenterDownloadReport', statusCode: error.response.status, data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(() => {});
                break;
            }

            case "GET_ADMIN_DASHBOARD_GRAPH":
                APIservice.get(action.payload.url)
                .then((response) => {
                    if (response && response.data) {
                        if(action.payload.comp_type==3){
                            store.dispatch({
                                type: "SET_DASHBOARD_CLEANER_GRAPH",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type==2){
                            store.dispatch({
                                type: "SET_DASHBOARD_PM_GRAPH",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type=='staff'){
                            store.dispatch({
                                type: "SET_DASHBOARD_STAFF_GRAPH",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type=='appointment'){
                            store.dispatch({
                                type: "SET_DASHBOARD_APPMT_GRAPH",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type=='analytics'){
                            store.dispatch({
                                type: "SET_DASHBOARD_ANALYTICS_COUNT",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type=='integration'){
                            store.dispatch({
                                type: "SET_DASHBOARD_INTEGRATION_LIST",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type === 'cleaner_clicks_by_date') {
                            store.dispatch({
                                type: "SET_DASHBOARD_TRACKED_EVENTS_CLEANER_CLICKS_BY_DATE",
                                payload: response.data.data,
                            });
                        }
                        if(action.payload.comp_type=='previous_year_appointment'){
                            store.dispatch({
                                type: "SET_DASHBOARD_PREV_YEAR_APPMT_GRAPH",
                                payload: response.data.data,
                            });
                        }
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "AdminDashboardGraph",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
            break;

            case "FETCH_BROADCAST_ALERT_LIST":
                endpoint = microServices.base + action.payload.url;
                APIservice.get(endpoint)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: "SET_BROADCAST_ALERT_LIST",
                            payload: response.data.data,
                        });
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "boradcastAlertList",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
            break;


        case "MARK_AS_READ_BROADCAST_ALERT" :
            endpoint = microServices.base+ action.payload.url;
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint,action.payload.formData)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                          message: action.payload.message,
                          componentName:action.payload.componentName
                        }
                      });
                }
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to Save!'
                    }
                  });
                } else {
                  let err = {componentName:action.payload.componentName, statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
            
        default:
            break;
    }
};

export default DashboardMiddleware;