const initState = {
  staffPlannerList : []
};

const StaffPlanner = (state = initState, action) => {

  switch (action.type) {
    case "SET_STAFF_PLANNER_LIST": {
      return {
        ...state,
        staffPlannerList: action.payload,
      }
    }

    case 'CLEAR_STAFF_PLANNER_LIST': {
      return {
        ...state,
        staffPlannerList : []
      };
    }
    default:
      return state;
  }
};

export default StaffPlanner;
